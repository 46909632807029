import React from 'react';

function Text({fill, stroke}) {
    return (
      
    //   <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path d="M14.9693 0.624833L20.2563 5.9012C21.0895 6.74499 21.0895 8.09079 20.2563 8.93458L10.4406 18.7503C8.77437 20.4166 6.0721 20.4166 4.39519 18.7503L0.624833 14.98C-0.208278 14.1362 -0.208278 12.7904 0.624833 11.9466L11.9466 0.624833C12.7904 -0.208278 14.1362 -0.208278 14.9693 0.624833ZM2.13084 13.4633L5.91188 17.2336C6.74499 18.0774 8.09079 18.0774 8.93458 17.2336L12.7049 13.4633L7.41789 8.17624L2.13084 13.4633Z" fill={fill}/>
    //   </svg>
      
      <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.9998 0L16.1598 4.35L15.1998 4.61C14.7498 3.74 14.2898 2.87 13.7598 2.43C13.2298 2 12.6098 2 11.9998 2H9.49984V12.5C9.49984 13 9.49984 13.5 9.82984 13.75C10.1698 14 10.8298 14 11.4998 14V15H5.49984V14C6.16984 14 6.82984 14 7.16984 13.75C7.49984 13.5 7.49984 13 7.49984 12.5V2H4.99984C4.38984 2 3.76984 2 3.23984 2.43C2.70984 2.87 2.24984 3.74 1.79984 4.61L0.839844 4.35L1.99984 0H14.9998Z" fill={fill}/>
</svg>
      
    );
}

export default Text