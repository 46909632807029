import React from 'react';
import './screenSizeWarning.css';
import warningImage from '../WhiteBoard/assets/Not supported.svg';

function screenSizeWarning() {
  return (
    <div>
      <div className="main">
        <img src={warningImage} />
        The service is not supported on this screen size
        <a href={process.env.REACT_APP_Home}>Go to dashboard</a>
      </div>
    </div>
  );
}

export default screenSizeWarning;
