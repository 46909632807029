import React, { useState , useEffect} from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Toast.css';


function Toaster({toastMessage,showToast}) {
  const [showA, setShowA] = useState(false);
  useEffect(() => {
    setShowA(showToast)
  }, [showToast]);

  return (
    <div>
      <ToastContainer style={{ zIndex: '99999' }} position="top-center" className="p-3">
        <Toast show={showA}>
          <Toast.Body className="toastBody" style={{background:toastMessage.type == "success"?"rgba(92, 192, 13, 0.05)":"rgb(220 53 69 / 33%)"}}>{toastMessage.message}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}

export default Toaster;
