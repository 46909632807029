import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useContext,
} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { fabric } from 'fabric';
import PdfReader from '../PdfReader';
import { saveAs } from 'file-saver';
import getCursor from './cursors';
// import './eraserBrush';

import Pencil from './assets/Pencil.js';
import Eraser from './assets/Eraser.js';
import Text from './assets/Text.js';
import captureIcon from './assets/Capture icon.svg';
import styles from './index.module.scss';
import { GlobalContext } from '../../../context/context';
import html2canvas from 'html2canvas';
import JSZip from 'jszip';
import FontFaceObserver from 'fontfaceobserver';

let drawInstance = null;
let origX;
let origY;
let mouseDown = false;

const options = {
  currentMode: '',
  currentColor: '#000000',
  currentWidth: 5,
  fill: false,
  group: {},
  currentAlign: ''
};

const modes = {
  RECTANGLE: 'RECTANGLE',
  TRIANGLE: 'TRIANGLE',
  ELLIPSE: 'ELLIPSE',
  LINE: 'LINE',
  PENCIL: 'PENCIL',
  ERASER: 'ERASER',
};


function stopDrawing() {
  mouseDown = false;
}

function removeCanvasListener(canvas) {
  canvas.off('mouse:down');
  canvas.off('mouse:move');
  canvas.off('mouse:up');
}

function changeToErasingMode(canvas) {
  deSelectAllObjects(canvas)
  canvas.selection = false
  if (options.currentMode !== modes.ERASER) {
    removeCanvasListener(canvas);
    canvas.isDrawingMode = false;
    options.currentMode = modes.ERASER;
    canvas.hoverCursor = `url(${getCursor({ type: 'eraser' })}), default`;
  }
}

function onSelectMode(canvas) {
  canvas.selection = true
  options.currentMode = '';
  canvas.isDrawingMode = false;
  removeCanvasListener(canvas);
  canvas.getObjects().map((item) => item.set({ selectable: true }));
  canvas.hoverCursor = 'all-scroll';
}

function deSelectAllObjects(canvas) {
  canvas?.discardActiveObject();
  canvas?.requestRenderAll();
}

function clearCanvas(canvas) {
  canvas.getObjects().forEach((item) => {
    if (item !== canvas.backgroundImage) {
      canvas.remove(item);
    }
  });
}
function draw(canvas) {
  if (options.currentMode !== modes.PENCIL) {
    removeCanvasListener(canvas);

    options.currentMode = modes.PENCIL;
    // canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
    canvas.freeDrawingBrush.width = parseInt(options.currentWidth, 10) || 1;
    canvas.freeDrawingCursor = `url(${getCursor({ type: 'pencil' })}), default`;
    canvas.isDrawingMode = true;
  }
}

function handleResize(callback) {
  const resize_ob = new ResizeObserver(callback);
  return resize_ob;
}

function resizeCanvas(canvas, whiteboard) {
  return () => {
    const ratio = canvas.getWidth() / canvas.getHeight();
    const whiteboardWidth = whiteboard.clientWidth;

    const scale = whiteboardWidth / canvas.getWidth();
    const zoom = canvas.getZoom() * scale;
    canvas.setDimensions({ width: whiteboardWidth, height: whiteboardWidth / ratio });
    canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
  };
}
let initialText = true;
let initialShape = true;
let undoArrayTemp = []


const Whiteboard = ({
  aspectRatio,
  selectedImage,
  resetImage,
  saveCanvas,
  refreshFilesList,
  type
}) => {

  const [canvasWidth, setCanvasWidth] = useState(100);
  const [isFill, setIsFill] = useState(false);
  const [strokeDisplay, setStrokeDisplay] = useState(false);
  const [tempData, settempData] = useState({});
  const [fileReaderInfo, setFileReaderInfo] = useState({
    file: '',
    totalPages: null,
    currentPageNumber: 1,
    currentPage: '',
  });
  const canvasRef = useRef(null);
  const whiteboardRef = useRef(null);
  const uploadImageRef = useRef(null);
  const uploadPdfRef = useRef(null);
  const ColorPickerRef = useRef(null);
  const { setBackdrop } = React.useContext(GlobalContext);
  const { currentPage, setCurrentPage } = React.useContext(GlobalContext);

  const { setTotalPage } = React.useContext(GlobalContext);
  const { setFileName } = React.useContext(GlobalContext);
  const { addTextTrigger } = React.useContext(GlobalContext);
  const { selectedFont } = React.useContext(GlobalContext);
  const { zoom } = React.useContext(GlobalContext);
  const { canvas, setCanvas, } = React.useContext(GlobalContext);
  const { canvasArray, setCanvasArray, } = React.useContext(GlobalContext);
  const { setCanvasImageArray } = React.useContext(GlobalContext);
  const { setToastMessage } = React.useContext(GlobalContext);
  const { setShowA } = React.useContext(GlobalContext);
  const { org_id } = React.useContext(GlobalContext);
  const { addShapeTrigger } = React.useContext(GlobalContext);
  const { selectedShape } = React.useContext(GlobalContext);
  const { selectedFile } = React.useContext(GlobalContext);
  // const { selectedFileType, setSelectedFileType } = React.useContext(GlobalContext);
  const { currentAlign } = React.useContext(GlobalContext);
  const { currentMode, setCurrentMode } = React.useContext(GlobalContext);
  const { brushWidth, setBrushWidth } = React.useContext(GlobalContext);
  const { undoArray, setundoArray } = React.useContext(GlobalContext);
  const { setredoArray } = React.useContext(GlobalContext);
  const { undoPagination } = React.useContext(GlobalContext);


  useEffect(() => {
    window.onbeforeunload = function (e) {
      return "You file is not saved!";
    }
    setBackdrop(true); //use effect to load file on file change
    setCanvasArray([]);
    setCanvasImageArray([]);
    setCanvasImageArray([]);
    setCurrentPage(1);
    getFile();
    setundoArray([]);
    setredoArray([]);
  }, [selectedFile]);

  useEffect(() => {
    addImageToCanvas(canvas, selectedImage); //use effect to load image on adding image to canvas
  }, [resetImage]);
  useEffect(() => {
    changeCurrentWidth(brushWidth)
  }, [brushWidth]);
  useEffect(() => {
    if (initialText) {
      initialText = false;
    } else {
      // createText(canvas);
      addText(canvas)
    }
  }, [addTextTrigger]);

  useEffect(() => {
    if (initialShape) {
      initialShape = false;
    } else {
      createShape(canvas);
    }
  }, [addShapeTrigger]);

  const initCanvas = (width, height) => {
    const canvas = new fabric.Canvas('canvas', { height, width, backgroundColor: '#fff' });
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerStyle = 'rectangle';
    fabric.Object.prototype.borderColor = '#D0D0D0';
    fabric.Object.prototype.cornerColor = '#000000';
    fabric.Object.prototype.cornerSize = 10;
    fabric.Object.prototype.padding = 10;
    fabric.Object.prototype.setControlsVisibility({
      mt: false, // middle top disable
      mb: false, // midle bottom
      ml: false, // middle left
      mr: false, // I think you get it
    });
    let redoArray = []
    canvas.on('object:added', (e) => {
      e.target.on('mousedown', removeObject(canvas));
      if (undoArrayTemp.length > 4) {
        undoArrayTemp.shift()
        undoArrayTemp.push(canvas.toJSON())
      } else {
        undoArrayTemp.push(canvas.toJSON())
      }
      setundoArray(undoArrayTemp)
      setredoArray([])
    });
    canvas.on('path:created', (e) => {
      e.path.on('mousedown', removeObject(canvas));
    });
    canvas.on("object:modified", function (e) {
      if (undoArrayTemp.length > 4) {
        undoArrayTemp.shift()
        undoArrayTemp.push(canvas.toJSON())
      } else {
        undoArrayTemp.push(canvas.toJSON())
      }
      setundoArray(undoArrayTemp)
    });

    document.onkeydown = function (e) {
      //delete on delete key pressed
      if (canvas.getActiveObject()) {
        switch (e.keyCode) {
          case 46: // delete
            if (canvas.getActiveObject().type !== 'i-text' || !canvas.getActiveObject().isEditing) {
              canvas.getActiveObjects().forEach((obj) => {
                canvas.remove(obj);
              });
            }
        }
        canvas.renderAll();
      }
    };

    return canvas;
  };
  useEffect(() => {
    if (canvas) { //use effect to load file on page change
      setBackdrop(true);
      canvas.clear()
      canvas?.loadFromJSON(
        canvasArray[currentPage - 1],
        function (o, object) {
          canvas.renderAll();
          if (canvas.getObjects().length > 0) {
            canvas.getObjects().forEach(object => {
              if (object.type == 'image') {
                var img = new Image();
                img.src = object.src
                img.onload = function () {
                  // console.log('loaded',object);
                  // setBackdrop(false);
                }
                img.onerror = function (ev) {
                  // console.log('error');
                  // setBackdrop(false);
                };
              } else {
              }
            })
            setBackdrop(false)

          } else {
            setBackdrop(false)
          }
        }
      );
      canvas.setBackgroundColor('white', function () {
        canvas.renderAll();
      });

      if (undoPagination[currentPage - 1]) {
        setundoArray([...undoPagination[currentPage - 1]?.undoArr])
        setredoArray([...undoPagination[currentPage - 1]?.redoArr])
      } else {
        setundoArray([])
        setredoArray([])
      }
      undoArrayTemp = []
      undoArrayTemp = undoArray
    }
  }, [currentPage]);
  useEffect(() => {
    // if (!canvas && canvasRef.current) {
    // const canvas = initCanvas(
    //   whiteboardRef.current.clientWidth,
    //   whiteboardRef.current.clientWidth / aspectRatio,
    // );
    const canvas = initCanvas(
      1920,
      1080,
    );
    setCanvas(() => canvas);
    onSelectMode(canvas);
    handleResize(resizeCanvas(canvas, whiteboardRef.current)).observe(whiteboardRef.current);
    // if(selectedFile?.unqId){
    //   getFile()
    //   console.log(canvas?._objects,"eteeee")
    // }
    // }
  }, [canvasRef]);
  useEffect(() => {
    setCanvasWidth(100 + zoom)
  }, [zoom]);
  useEffect(() => {
    setBackdrop(true)
    if (tempData.data) {
      setCanvasArray(tempData.data?.canvas);
      setCanvasImageArray(tempData.data?.canvasImageArray);
      setTotalPage(tempData.data?.canvas.length);
      setFileName(tempData.data?.name);
      if (tempData.data?.canvas?.length > 0) {
        // canvas?.clear();
        setBackdrop(true)
        canvas?.renderAll()
        canvas?.loadFromJSON(
          tempData.data?.canvas[0],
          function () {
            canvas.renderAll();
            if (canvas.getObjects().length > 0) {
              canvas.getObjects().forEach(object => {

                if (object.type == 'image') {
                  var img = new Image();
                  img.src = object.src
                  img.onload = function () {
                  }
                  img.onerror = function (ev) {
                  };

                } else {

                }
              })
              setBackdrop(false)
            } else {
              setBackdrop(false)
            }
          }
        );
        canvas?.setBackgroundColor('white', canvas.renderAll.bind(canvas));
        setBackdrop(false)

      } else {
        // addNewCanvas()
        setBackdrop(false)

      }
    }
  }, [tempData?.data?.unqId]);
  useEffect(() => {
    if (canvas) {
      const center = canvas.getCenter();
      fabric.Image.fromURL(fileReaderInfo.currentPage, (img) => {
        img.scaleToHeight(canvas.height);
        canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
          top: center.top,
          left: center.left,
          originX: 'center',
          originY: 'center',
        });
        canvas.renderAll();
      });
    }
  }, [fileReaderInfo.currentPage]);
  function uploadImage(e) {
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.addEventListener('load', () => {
      fabric.Image.fromURL(reader.result, (img) => {
        img.scaleToHeight(canvas.height);
        canvas.add(img);
      });
    });

    reader.readAsDataURL(file);
  }
  const getFile = async () => {
    setBackdrop(true)
    await axios
      .post(process.env.REACT_APP_API_URL + `file/getOne`, {
        unqId: selectedFile,
      })
      .then((res) => {
        setBackdrop(false);
        if (res.data?.canvas) {
          settempData(res)
        }
      });
  }
  function addImageToCanvas(canvas, image) {
    setBackdrop(true);
    fabric.Image.fromURL(
      'https://moonhive-server.in.net/core-php/kko/v1/image.php?' + image.url,
      function (img) {
        img.scaleToHeight(canvas?.height);
        canvas?.add(img);
        setBackdrop(false);
      }.bind(this),
      {
        crossOrigin: 'anonymous',
      },
    );
  }
  function createText(canvas) {
    if (canvas) {
      removeCanvasListener(canvas);
      canvas.isDrawingMode = false;
      const text = new fabric.Textbox('Text', {
        left: canvas?.width / 2,
        top: canvas?.height / 2,
        fill: options.currentColor,
        editable: true,
      });

      var myfont = new FontFaceObserver(selectedFont);
      myfont
        .load()
        .then(function () {
          // when font is loaded, use it.
          text.set('fontFamily', selectedFont);
          text.set('fontSize', '20');
          canvas?.add(text);
          canvas?.requestRenderAll();
          canvas?.setActiveObject(text)
        })
        .catch(function (e) {
        });
      canvas.renderAll();
    }
  }
  function addText(canvas) {
    if (canvas) {
      onSelectMode(canvas);
      // handleCurrentMode('SELECT');
      removeCanvasListener(canvas);

      canvas.isDrawingMode = false;
      const text = new fabric.IText('Text', {
        left: canvas?.width / 2,
        top: canvas?.height / 2,
        fontFamily: 'arial',
        fill: options?.currentColor,
        editable: true,
        fontWeight: 'bold',
        fixedWidth: 300,
        textAlign: currentAlign ? currentAlign : 'left'
      });
      text.set('fontSize', 32);
      canvas?.add(text);
      if (options.currentMode !== modes.ERASER) {
        canvas?.setActiveObject(text)
      }
      options.currentAlign = text?.textAlign
      // var myfont = new FontFaceObserver(selectedFont);
      // myfont
      //   .load()
      //   .then(function () {
      //     // when font is loaded, use it.
      //     text.set('fontFamily', selectedFont);
      //     text.set('fontSize', '20');
      //     canvas?.add(text);
      //     canvas?.requestRenderAll();
      //     canvas?.setActiveObject(text)
      //   })
      //   .catch(function (e) {
      //     console.log(e);
      //     console.log('font loading failed ' + selectedFont);
      //   });
      canvas.renderAll();
    }
  }
  function createShape(canvas) {
    if (canvas) {
      removeCanvasListener(canvas);
      canvas.isDrawingMode = false;
      setCurrentMode('SELECT');
      onSelectMode(canvas);
      switch (selectedShape) {
        case 'Rectangle':
          var rec = new fabric.Rect({
            top: canvas?.height / 2 || 10,
            left: canvas?.width / 2 || 10,
            width: 200,
            height: 120,
            fill: '#67EBD3',
            stroke: '',
            strokeWidth: 2
          });
          canvas?.add(rec)
          canvas?.setActiveObject(rec)
          ColorPickerRef.current.value = '#67EBD3'
          options.currentColor = '#67EBD3'
          canvas.freeDrawingBrush.color = '#67EBD3'
          break;
        case 'Circle':
          var cir = new fabric.Circle({
            top: canvas?.height / 2 || 10,
            left: canvas?.width / 2 || 10,
            radius: 100,
            fill: '#F4A28C',
            stroke: '',
            strokeWidth: 2
          });
          canvas?.add(cir)
          canvas?.setActiveObject(cir)
          ColorPickerRef.current.value = '#F4A28C'
          options.currentColor = '#F4A28C'
          canvas.freeDrawingBrush.color = '#F4A28C'
          break;
        case 'Triangle':
          var tri = new fabric.Triangle({
            top: canvas?.height / 2 || 10,
            left: canvas?.width / 2 || 10,
            width: 200,
            height: 173.2,
            fill: '#73CDFF',
            stroke: '',
            strokeWidth: 2
          });
          canvas?.add(tri)
          canvas?.setActiveObject(tri)
          ColorPickerRef.current.value = '#73CDFF'
          options.currentColor = '#73CDFF'
          canvas.freeDrawingBrush.color = '#73CDFF'
          break;
        case 'Star':
          var points = starPolygonPoints(5, 85, 35);
          var Star = new fabric.Polygon(points, {
            stroke: '',
            top: canvas?.height / 2 || 10,
            left: canvas?.width / 2 || 10,
            strokeWidth: 0,
            strokeLineJoin: 'bevil',
            fill: '#A184FF'
          }, false);
          canvas.add(Star);
          canvas?.setActiveObject(Star)
          ColorPickerRef.current.value = '#A184FF'
          options.currentColor = '#A184FF'
          canvas.freeDrawingBrush.color = '#A184FF'
          break;
        case 'TriangleOutline':
          var tri = new fabric.Triangle({
            top: canvas?.height / 2 || 10,
            left: canvas?.width / 2 || 10,
            width: 200,
            height: 173.2,
            fill: '',
            stroke: '#73CDFF',
            strokeWidth: 4
          });
          canvas?.add(tri)
          canvas?.setActiveObject(tri)
          ColorPickerRef.current.value = '#73CDFF'
          options.currentColor = '#73CDFF'
          canvas.freeDrawingBrush.color = '#73CDFF'
          break;
        case 'StarOutline':
          var points = starPolygonPoints(5, 85, 35);
          var Star = new fabric.Polygon(points, {
            stroke: '#A184FF',
            top: canvas?.height / 2 || 10,
            left: canvas?.width / 2 || 10,
            strokeWidth: 4,
            strokeLineJoin: 'bevil',
            fill: ''
          }, false);
          canvas.add(Star);
          canvas?.setActiveObject(Star)
          ColorPickerRef.current.value = '#A184FF'
          options.currentColor = '#A184FF'
          canvas.freeDrawingBrush.color = '#A184FF'
          break;
        case 'CircleOutline':
          var cir = new fabric.Circle({
            top: canvas?.height / 2 || 10,
            left: canvas?.width / 2 || 10,
            radius: 100,
            fill: '',
            stroke: '#F4A28C',
            strokeWidth: 4
          });
          canvas?.add(cir)
          canvas?.setActiveObject(cir)
          options.currentColor = '#67EBD3'
          ColorPickerRef.current.value = '#67EBD3'
          canvas.freeDrawingBrush.color = '#67EBD3'
          break;
        case 'RectangleOutline':
          var rec = new fabric.Rect({
            top: canvas?.height / 2 || 10,
            left: canvas?.width / 2 || 10,
            width: 200,
            height: 120,
            fill: '',
            stroke: '#67EBD3',
            strokeWidth: 4
          });
          canvas?.add(rec)
          canvas?.setActiveObject(rec)
          options.currentColor = '#67EBD3'
          ColorPickerRef.current.value = '#67EBD3'
          canvas.freeDrawingBrush.color = '#67EBD3'
          break;
        case 'Line':
          var line = new fabric.Line([300, 200, 600, 200], {
            stroke: '#73cdff',
            strokeWidth: 5,
          });
          canvas?.add(line)
          canvas?.setActiveObject(line)
          options.currentColor = '#73cdff'
          ColorPickerRef.current.value = '#73cdff'
          canvas.freeDrawingBrush.color = '#73cdff'
          break;

        default:
          break;
      }
      canvas.renderAll();
    }
  }

  function changeColor(e) {
    if (canvas.getActiveObject()) {
      if (canvas.getActiveObject()?._objects) {
        canvas.getActiveObject()?._objects.forEach((el) => {
          if (el.fill) {
            el?.set('fill', e.target.value);
          } else {
            el?.set('stroke', e.target.value);
          }
        })
      } else {
        if (canvas.getActiveObject().fill) {
          canvas.getActiveObject()?.set('fill', e.target.value);
        } else {
          canvas.getActiveObject()?.set('stroke', e.target.value);
        }
      }
      // console.log(canvas.getActiveObject().type,canvas.getActiveObject())

      canvas.renderAll();
    }
  }

  function changeCurrentWidth(e) {
    const intValue = parseInt(e);
    options.currentWidth = intValue;
    if (canvas) {
      canvas.freeDrawingBrush.width = intValue;
    }
    setBrushWidth(() => intValue);
    // setCurrentMode('PENCIL');
    // draw(canvas);
    // setStrokeDisplay(false)
  }

  function removeObject(canvas) {
    return (e) => {
      if (options.currentMode === modes.ERASER) {
        canvas.remove(e.target);
      } else if (e.target.get('type') == 'i-text') {
        options.currentAlign = e.target?.textAlign
      }
    };
  }

  function changeCurrentColor(e) {
    options.currentColor = e.target.value;
    canvas.freeDrawingBrush.color = e.target.value;
  }

  function changeFill(e) {
    options.fill = e.target.checked;
    setIsFill(() => e.target.checked);
  }

  function onSaveCanvasAsImage() {
    canvasRef.current.toBlob(function (blob) {
      saveAs(blob, 'image.png');
      Handletoaster('success', 'Screenshot saved');
    });
  }

  function onFileChange(event) {
    updateFileReaderInfo({ file: event.target.files[0], currentPageNumber: 1 });
  }

  function updateFileReaderInfo(data) {
    setFileReaderInfo({ ...fileReaderInfo, ...data });
  }
  function logger(e) {
  }
  function handleCurrentMode(mode) {
    setCurrentMode(mode);
    if (mode == 'TEXT') {
      addText(canvas)
      onSelectMode(canvas);
    } else
      if (currentMode != mode) {
        if (mode == 'PENCIL') {
          draw(canvas);
        } else if (mode == 'ERASER') {
          changeToErasingMode(canvas);
        } else {
          onSelectMode(canvas);
        }
      } else {
        setCurrentMode('SELECT');
        onSelectMode(canvas);
      }

  }
  function handleZoom() {
    canvas.on('mouse:wheel', function (opt) {
      var delta = opt.e.deltaY;
      var pointer = canvas.getPointer(opt.e);
      var zoom = canvas.getZoom();
      zoom = zoom + delta / 200;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });
  }
  const Handletoaster = (type, message) => {
    setToastMessage({ type: type, message: message });
    setShowA(true);
    setTimeout(() => {
      setShowA(false);
    }, 2000);
  };

  function starPolygonPoints(spikeCount, outerRadius, innerRadius) {
    var rot = Math.PI / 2 * 3;
    var cx = outerRadius;
    var cy = outerRadius;
    var sweep = Math.PI / spikeCount;
    var points = [];
    var angle = 60;

    for (var i = 0; i < spikeCount; i++) {
      var x = cx + Math.cos(angle) * outerRadius;
      var y = cy + Math.sin(angle) * outerRadius;
      points.push({ x: x, y: y });
      angle += sweep;

      x = cx + Math.cos(angle) * innerRadius;
      y = cy + Math.sin(angle) * innerRadius;
      points.push({ x: x, y: y });
      angle += sweep
    }
    return (points);
  }

  return (
    <div style={{ width: canvasWidth + '%' }}>
      <div ref={whiteboardRef} className={styles.whiteboard}>
        <div className={styles.drawTools}>
          <input
            ref={uploadPdfRef}
            accept=".pdf"
            style={{ display: 'none' }}
            type="file"
            onChange={onFileChange}
          />
          <input
            ref={uploadImageRef}
            accept="image/*"
            style={{ display: 'none' }}
            type="file"
            onInput={uploadImage}
          />
          <div
            className={currentMode == 'PENCIL' ? styles.toolsActive : styles.tools}
            onMouseEnter={() => { setStrokeDisplay(true) }}
            onMouseLeave={() => { setStrokeDisplay(false) }}
          >
            <div className={styles.divCenter} onClick={() => {
              draw(canvas);
              handleCurrentMode('PENCIL');
            }}>
              <Pencil fill={currentMode == 'PENCIL' ? '#0096D6' : '#454545'} stroke="yellow" />
              <p>Pen</p>
            </div>
            {/* {strokeDisplay && (
            <div className={styles.strokeContainer}>
              <div className={brushWidth == 5 ? styles.strokesActive : styles.strokes} onClick={()=>{changeCurrentWidth(5)}}>
                <div className={styles.stroke}  style={{height:"5px"}}></div>
              </div>
              <div className={brushWidth == 10 ? styles.strokesActive : styles.strokes} onClick={()=>{changeCurrentWidth(10)}}>
                <div className={styles.stroke}  style={{height:"8px"}}></div>
              </div>
              <div className={brushWidth == 20 ? styles.strokesActive : styles.strokes} onClick={()=>{changeCurrentWidth(20)}}>
                <div className={styles.stroke}  style={{height:"12px"}}></div>
              </div>
            </div>
            )} */}
          </div>
          <div
            className={currentMode == 'ERASER' ? styles.toolsActive : styles.tools}
            onClick={() => {
              changeToErasingMode(canvas);
              handleCurrentMode('ERASER');
            }}
          >
            <div className={styles.divCenter}>
              <Eraser fill={currentMode == 'ERASER' ? '#0096D6' : '#454545'} stroke="yellow" />
              <p>Eraser</p>
            </div>
          </div>
          <div
            className={currentMode == 'TEXT' ? styles.toolsActive : styles.tools}
            onClick={() => {
              // changeToErasingMode(canvas);
              handleCurrentMode('TEXT');
            }}
          >
            <div className={styles.divCenter}>
              <Text fill={currentMode == 'TEXT' ? '#0096D6' : '#454545'} stroke="yellow" />
              <p>TEXT</p>
            </div>
          </div>
          <div className={styles.tools} onClick={() => ColorPickerRef.current.click()}>
            <input
              ref={ColorPickerRef}
              type="color"
              name="color"
              id="color"
              onChange={(e) => { changeCurrentColor(e); changeColor(e) }}
            />
            <p>Color</p>
          </div>
          <div className={styles.tools} onClick={onSaveCanvasAsImage}>
            <img src={captureIcon} />
            <p>Capture</p>
          </div>

        </div>
        <div className={styles.whiteBoardWrap}>
          <canvas ref={canvasRef} id="canvas" />
        </div>
      </div>
    </div>
  );
};

Whiteboard.propTypes = {
  aspectRatio: PropTypes.number,
};

export default Whiteboard;
