import React, { createContext, useState } from 'react';

export const GlobalContext = createContext('testing')


export default function GlobalProvider({ children }) {

  const [search, setSearch] = useState('search from context')
  const [exportToPdf, setExportToPdf] = useState(false)
  const [backDrop, setBackdrop] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [fileName, setFileName] = useState('');
  const [addTextTrigger, setAddTextTrigger] = useState(false);
  const [addShapeTrigger, setAddShapeTrigger] = useState(false);
  const [exportModalShow, setExportModalShow] = useState({ show: false, unqId: null, type: 'file' });
  const [zoomModalShow, setZoomModalShow] = useState({ show: false, unqId: null, type: 'file' });
  const [selectedFont, setSelectedFont] = useState('Times');
  const [selectedShape, setSelectedShape] = useState('Rectangle');
  const [totalPage, setTotalPage] = useState(1);
  const [zoom, setZoom] = useState(0);
  const [canvas, setCanvas] = useState(null);
  const [canvasArray, setCanvasArray] = useState([]);
  const [canvasImageArray, setCanvasImageArray] = useState([]);
  const [showA, setShowA] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState({
    type: 'success',
    message: 'Test message',
  });
  const [open, setOpen] = React.useState(false);

  const [currentAlign, setCurrentAlign] = React.useState('left');
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [selectedFileType, setSelectedFileType] = React.useState(null);
  const [files, setFiles] = React.useState([]);
  const [CurrentTab, setCurrentTab] = React.useState('files');
  const [searchKey, setSearchKey] = React.useState('');
  const [currentMode, setCurrentMode] = useState('');
  const [brushWidth, setBrushWidth] = useState(5);
  const [textColor, settextColor] = useState('black');


  const [org_id, setOrg_id] = React.useState('');
  const [userInfo, setUserInfo] = React.useState({});
  const [access, setAccess] = React.useState('');
  const [selectedOrgDetail, setSelectedOrgDetail] = React.useState({});
  const [selectedPlanDetail, setSelectedPlanDetail] = React.useState({});
  const [initialOrgFetch, setInitialOrgFetch] = React.useState({});
  const [roles, setRoles] = React.useState([]);
  const [innerNavItems, setInnerNavItems] = React.useState({});
  const [role, setRole] = React.useState();
  const [orgDetail, setOrgDetail] = React.useState([]);
  const [selectedFileTrigger, setSelectedFileTrigger] = React.useState(false);
  const [appList, setApplist] = React.useState([]);
  const [appMenu, setAppMenu] = React.useState({
    LMS: {
      name: 'LMS',
      menu: [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: 'layout/dashboard/home/prime',
        },
        {
          name: 'Departments',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Department.svg',
          url: 'layout/dashboard/home/department',
        },
        {
          name: 'Classrooms',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Classroom.svg',
          url: 'layout/dashboard/home',
        },
        {
          name: 'Payments',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Payments.svg',
          url: 'layout/dashboard/payment',
        },
        {
          name: 'Clubs',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Clubs.svg',
          url: 'layout/dashboard/chat/list',
        },
        {
          name: 'Users',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Admissions.svg',
          url: 'layout/dashboard/home/admission-decision',
        },
        {
          name: 'Students',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2Fstudent.svg',
          url: 'layout/dashboard/home/all-students',
        },
        {
          name: 'Notifications',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
          url: 'layout/dashboard/home/add-notification',
        },
      ],
    },
    CRM: {
      name: 'CRM',
      menu: [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: 'dashboard',
          fullUrl: '',
        },
        {
          name: 'Contacts',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FCRM-Contacts.svg',
          url: 'contacts',
          fullUrl: '',
        },
        {
          name: 'Users',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FCRM-Contacts.svg',
          url: 'users',
          fullUrl: '',
        },
        {
          name: 'Sales',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FCRM-Sales.svg',
          children: [
            {
              name: 'Overview',
              icon: '',
              url: 'sales/overview',
              fullUrl: '',
            },
            {
              name: 'Deals',
              icon: '',
              url: 'sales/deals',
              fullUrl: '',
            },
            {
              name: 'Tasks',
              icon: '',
              url: 'sales/tasks',
              fullUrl: ''
            },
            {
              name: 'Meetings',
              icon: '',
              url: 'sales/meetings',
              fullUrl: ''
            },
            {
              name: 'Calls',
              icon: '',
              url: 'sales/calls',
              fullUrl: ''
            }
          ],
        },
        {
          name: 'Tickets',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FCRM-tickets.svg',
          url: 'tickets',
          fullUrl: '',
        },

        {
          name: 'Marketing',
          icon: 'https://cdn.oogyy.com/oogyy/banner/2022/12/Marketing.svg',
          url: 'marketing',
          fullUrl: '',
        },
        {
          name: 'Settings',
          icon: 'https://cdn.oogyy.com/oogyy/banner/2022/12/CRM-Settings.svg',
          url: 'settings',
          fullUrl: '',
        },
      ],
    },
    Forms: {
      name: 'Forms',
      menu: [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: '',
        },
        {
          name: 'Forms',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FForm_Builder.svg',
          url: 'forms',
        },
      ],
    },
    StudyMaterials: {
      name: 'StudyMaterials',
      menu: [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: '',
        },
      ],
    },
    Whiteboard: {
      name: 'Whiteboard',
      menu: [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: '',
        },
      ],
    },
    Analytics: {
      name: 'Analytics',
      menu: [
        {
          name: 'Institution',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FInstitutional-analytics.svg',
          url: 'institution-analytics',
        },
        {
          name: 'LMS',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLms-analytics.svg',
          url: 'lms-analytics',
        },
        {
          name: 'Student',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FStudent-analytics.svg',
          url: 'student-analytics',
        },
      ],
    },
    WebsiteBuilder: {
      name: 'Website Builder',
      menu: [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: 'dashboard',
        },
        {
          name: 'Published Page',
          icon: 'https://cdn.oogyy.com/admin-assets/published.svg',
          url: 'Published-Page',
        },
        {
          name: 'Templates',
          icon: 'https://cdn.oogyy.com/admin-assets/Templates.svg',
          url: 'templates',

        },{
          name: 'Drafts',
          icon: 'https://cdn.oogyy.com/admin-assets/Drafts.svg',
          url: 'drafts',
        },
      ],
    },
    LandingpageBuilder: {
      name: 'Landingpage Builder',
      menu: [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: 'dashboard',
        },
        {
          name: 'Published Page',
          icon: 'https://cdn.oogyy.com/admin-assets/published.svg',
          url: 'Published-Page',
        },
        {
          name: 'Templates',
          icon: 'https://cdn.oogyy.com/admin-assets/Templates.svg',
          url: 'templates',

        }, {
          name: 'Drafts',
          icon: 'https://cdn.oogyy.com/admin-assets/Drafts.svg',
          url: 'drafts',
        },
      ],
    },
    WAMessager: {
      name: 'WAMessager',
      menu: [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: 'whatsapp/onboarding',
          fullUrl: '',
        },
        {
          name: 'Contacts',
          icon: 'https://cdn.oogyy.com/oogyy/app_icons/2023/Whatsapp-Contacts.svg',
          children: [
            {
              name: 'CRM Contacts',
              icon: '',
              url: 'contacts/crm',
              fullUrl: '',
            },
            {
              name: 'Other Contacts',
              icon: '',
              url: 'contacts/other',
              fullUrl: '',
            },
          ],
        },
        {
          name: 'Messages',
          icon: 'https://cdn.oogyy.com/oogyy/app_icons/2023/Whatsapp-Messages.svg',
          children: [
            // {
            //   name: 'Overview',
            //   icon: '',
            //   url: 'whatsapp/overview',
            //   fullUrl: '',
            // },
            {
              name: 'Send bulk messages',
              icon: '',
              url: 'messages/broadcast',
              fullUrl: '',
            },
            // {
            //   name: 'Chats',
            //   icon: '',
            //   url: 'whatsapp/chats',
            //   fullUrl: '',
            // },
          ],
        },
        {
          name: 'Templates',
          icon: 'https://cdn.oogyy.com/oogyy/app_icons/2023/Whatsapp-Templates.svg',
          url: 'templates/listing',
          fullUrl: '',
        },
        // {
        //   name: 'Sent',
        //   icon: 'assets/icons/navigation/Whatsapp-Sent.svg',
        //   url: 'whatsapp/sent',
        //   fullUrl: '',
        // },
        // {
        //   name: 'Account Details',
        //   icon: 'assets/icons/navigation/Whatsapp-ProfileSettings.svg',
        //   url: 'whatsapp/account-details',
        //   fullUrl: '',
        // },
      ],

    },
    HRM: {
      name: 'HRM',
      menu: [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: 'dashboard',
          fullUrl: '',
        },
        {
          name: 'Employee',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FHRM-Employee.svg',
          children: [
            {
              name: 'Overview',
              icon: '',
              url: 'employee/overview',
              fullUrl: '',
            },
            {
              name: 'Directory',
              icon: '',
              url: 'employee/directory',
              fullUrl: '',
            },
          ],
        },
        {
          name: 'Leave',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FHRM-Leave.svg',
          children: [
            {
              name: 'Overview',
              icon: '',
              url: 'leave/overview',
              fullUrl: '',
            },
            {
              name: 'Leave',
              icon: '',
              url: 'leavelist',
              fullUrl: '',
            },
            {
              name: 'Leave Granter',
              icon: '',
              url: 'leave/grantleave',
              fullUrl: '',
            },
            {
              name: 'Apply on behalf',
              icon: '',
              url: 'leave/leaveapply',
              fullUrl: '',
            },
            {
              name: 'Leave Calendar',
              icon: '',
              url: 'leave/leavecalender',
              fullUrl: '',
            },
            {
              name: 'Holiday',
              icon: '',
              url: 'leave/holiday',
              fullUrl: '',
            },
            {
              name: 'Setup',
              icon: '',
              url: 'leave/setup',
              fullUrl: '',
            },
          ],
        },
        {
          name: 'ID Card',
          icon: 'https://campuslift.blr1.cdn.digitaloceanspaces.com/oogyy/Profile%20Square%202.svg',
          url: '/idcard',
          fullUrl: ''
        },
        {
          name: 'Attendance',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FHRM-Attendance.svg',
          children: [
            {
              name: 'Attendance Muster',
              icon: '',
              url: 'attendance/overview',
              fullUrl: '',
            },
            {
              name: 'Process Attendance',
              icon: '',
              url: 'attendance/process',
              fullUrl: '',
            },
          ],
        },
      ],
    },
    Accounting: {
      name: 'Accounting',
      menu: [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: 'accounting/dashboard',
          fullUrl: '',
        },
        {
          name: 'Sales & Payments',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FAccounting-Sales-Payment.svg',
          children: [
            {
              name: 'Invoices',
              icon: '',
              route: 'accounting/invoices',
            },
            {
              name: 'Customers',
              icon: '',
              route: 'accounting/customers',
            },
            {
              name: 'Products and Services',
              icon: '',
              route: 'accounting/product-services',
            },
          ],
        },
        {
          name: 'Purchases',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FAccounting-Purchases.svg',
          children: [
            {
              name: 'Bills',
              icon: '',
              route: 'accounting/bills',
            },
            {
              name: 'Vendors',
              icon: '',
              route: 'accounting/vendors',
            },
            {
              name: 'Products and Services',
              icon: '',
              route: 'accounting/productNservices',
            },
          ],
        },
        {
          name: 'Accounting',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FAccounting-Accounting.svg',
          children: [
            {
              name: 'Transactions',
              icon: '',
              route: 'accounting/transaction',
            },
            {
              name: 'Reconciliation',
              icon: '',
              route: 'accounting/reconciliation',
            },
            {
              name: 'Chart of accounts',
              icon: '',
              route: 'accounting/chartOfAccounts',
            },
          ],
        },
      ],
    },
  });
  const [appMenuMobile, setAppMenuMobile] = React.useState({
    LMS: {
      name: 'LMS',
      menu: [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home/prime',
        },
        {
          name: 'Departments',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Department.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home/department',
        },
        {
          name: 'Classrooms',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Classroom.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home',
        },
        {
          name: 'Payments',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Payments.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/payment',
        },
        {
          name: 'Clubs',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Clubs.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/chat/list',
        },
        {
          name: 'Users',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Admissions.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home/admission-decision',
        },
        {
          name: 'Students',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2Fstudent.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home/all-students',
        },
        {
          name: 'Approval',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Approval.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home/approve',
        },
        {
          name: 'Notifications',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home/add-notification',
        },
      ],
    },
    CRM: {
      name: 'CRM',
      menu: [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: process.env.REACT_APP_CRM + 'dashboard',
          fullUrl: '',
        },
        {
          name: 'Contacts',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FCRM-Contacts.svg',
          url: process.env.REACT_APP_CRM + 'contacts',
          fullUrl: '',
        },
        {
          name: 'Users',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FCRM-Contacts.svg',
          url: process.env.REACT_APP_CRM + 'users',
          fullUrl: '',
        },
        {
          name: 'Sales',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FCRM-Sales.svg',
          children: [
            {
              name: 'Overview',
              icon: '',
              url: process.env.REACT_APP_CRM + 'sales/overview',
              fullUrl: '',
            },
            {
              name: 'Deals',
              icon: '',
              url: process.env.REACT_APP_CRM + 'sales/deals',
              fullUrl: '',
            },
            {
              name: 'Tasks',
              icon: '',
              url: process.env.REACT_APP_CRM + 'sales/tasks',
              fullUrl: ''
            },
            {
              name: 'Meetings',
              icon: '',
              url: process.env.REACT_APP_CRM + 'sales/meetings',
              fullUrl: ''
            },
            {
              name: 'Calls',
              icon: '',
              url: process.env.REACT_APP_CRM + 'sales/calls',
              fullUrl: ''
            }
          ],
        },
        {
          name: 'Tickets',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FCRM-tickets.svg',
          url: process.env.REACT_APP_CRM + 'tickets',
          fullUrl: '',
        },

        {
          name: 'Marketing',
          icon: 'https://cdn.oogyy.com/oogyy/banner/2022/12/Marketing.svg',
          url: process.env.REACT_APP_CRM + 'marketing',
          fullUrl: '',
        },
        {
          name: 'Settings',
          icon: 'https://cdn.oogyy.com/oogyy/banner/2022/12/CRM-Settings.svg',
          url: process.env.REACT_APP_CRM + 'settings',
          fullUrl: '',
        },
      ],
    },
    Forms: {
      name: 'Forms',
      menu: [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: process.env.REACT_APP_Forms,
        },
        {
          name: 'Forms',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FForm_Builder.svg',
          url: process.env.REACT_APP_Forms + 'forms',
        },
      ],
    },
    StudyMaterials: {
      name: 'StudyMaterials',
      menu: [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: '',
          app: 'StudyMaterial'
        },
      ],
    },
    Whiteboard: {
      name: 'Whiteboard',
      menu: [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: process.env.REACT_APP_Whiteboard,
        },
      ],
    },
    Analytics: {
      name: 'Analytics',
      menu: [
        {
          name: 'Institution',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FInstitutional-analytics.svg',
          url: 'institution-analytics',
        },
        {
          name: 'LMS',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLms-analytics.svg',
          url: 'lms-analytics',
        },
        {
          name: 'Student',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FStudent-analytics.svg',
          url: 'student-analytics',
        },
      ],
    },
    WebsiteBuilder: {
      name: 'Website Builder',
      menu: [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: 'dashboard',
        },
        {
          name: 'Published Page',
          icon: 'https://cdn.oogyy.com/admin-assets/published.svg',
          url: 'Published-Page',
        },
        {
          name: 'Templates',
          icon: 'https://cdn.oogyy.com/admin-assets/Templates.svg',
          url: 'templates',

        }, {
          name: 'Drafts',
          icon: 'https://cdn.oogyy.com/admin-assets/Drafts.svg',
          url: 'drafts',
        },
      ],
    },
    LandingpageBuilder: {
      name: 'Landingpage Builder',
      menu: [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: 'dashboard',
        },
        {
          name: 'Published Page',
          icon: 'https://cdn.oogyy.com/admin-assets/published.svg',
          url: 'Published-Page',
        },
        {
          name: 'Templates',
          icon: 'https://cdn.oogyy.com/admin-assets/Templates.svg',
          url: 'templates',

        }, {
          name: 'Drafts',
          icon: 'https://cdn.oogyy.com/admin-assets/Drafts.svg',
          url: 'drafts',
        },
      ],
    },
    // WebsiteBuilder: {
    //   name: 'Website Builder',
    //   menu: [
    //     {
    //       name: 'Dashboard',
    //       icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
    //       url: 'dashboard',
    //     },
    //     {
    //       name: 'Published Page',
    //       icon: 'https://cdn.oogyy.com/admin-assets/published.svg',
    //       url: 'Published-Page',
    //     },
    //     {
    //       name: 'Templates',
    //       icon: 'https://cdn.oogyy.com/admin-assets/Templates.svg',
    //       url: 'templates',

    //     }, {
    //       name: 'Drafts',
    //       icon: 'https://cdn.oogyy.com/admin-assets/Drafts.svg',
    //       url: 'drafts',
    //     },
    //   ],
    // },
    WAMessager: {
      name: 'WAMessager',
      menu: [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: 'whatsapp/onboarding',
          fullUrl: '',
        },
        {
          name: 'Contacts',
          icon: 'https://cdn.oogyy.com/oogyy/app_icons/2023/Whatsapp-Contacts.svg',
          children: [
            {
              name: 'CRM Contacts',
              icon: '',
              url: 'contacts/crm',
              fullUrl: '',
            },
            {
              name: 'Other Contacts',
              icon: '',
              url: 'contacts/other',
              fullUrl: '',
            },
          ],
        },
        {
          name: 'Messages',
          icon: 'https://cdn.oogyy.com/oogyy/app_icons/2023/Whatsapp-Messages.svg',
          children: [
            // {
            //   name: 'Overview',
            //   icon: '',
            //   url: 'whatsapp/overview',
            //   fullUrl: '',
            // },
            {
              name: 'Send bulk messages',
              icon: '',
              url: 'messages/broadcast',
              fullUrl: '',
            },
            // {
            //   name: 'Chats',
            //   icon: '',
            //   url: 'whatsapp/chats',
            //   fullUrl: '',
            // },
          ],
        },
        {
          name: 'Templates',
          icon: 'https://cdn.oogyy.com/oogyy/app_icons/2023/Whatsapp-Templates.svg',
          url: 'templates/listing',
          fullUrl: '',
        },
        // {
        //   name: 'Sent',
        //   icon: 'assets/icons/navigation/Whatsapp-Sent.svg',
        //   url: 'whatsapp/sent',
        //   fullUrl: '',
        // },
        // {
        //   name: 'Account Details',
        //   icon: 'assets/icons/navigation/Whatsapp-ProfileSettings.svg',
        //   url: 'whatsapp/account-details',
        //   fullUrl: '',
        // },
      ],

    },
    HRM: {
      name: 'HRM',
      menu: [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: process.env.REACT_APP_HRM + 'dashboard',
          fullUrl: '',
        },
        {
          name: 'Employee',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FHRM-Employee.svg',
          children: [
            {
              name: 'Overview',
              icon: '',
              url: process.env.REACT_APP_HRM + 'employee/overview',
              fullUrl: '',
            },
            {
              name: 'Directory',
              icon: '',
              url: process.env.REACT_APP_HRM + 'employee/directory',
              fullUrl: '',
            },
          ],
        },
        {
          name: 'Leave',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FHRM-Leave.svg',
          children: [
            {
              name: 'Overview',
              icon: '',
              url: process.env.REACT_APP_HRM + 'leave/overview',
              fullUrl: '',
            },
            {
              name: 'Leave',
              icon: '',
              url: process.env.REACT_APP_HRM + 'leavelist',
              fullUrl: '',
            },
            {
              name: 'Leave Granter',
              icon: '',
              url: process.env.REACT_APP_HRM + 'leave/grantleave',
              fullUrl: '',
            },
            {
              name: 'Apply on behalf',
              icon: '',
              url: process.env.REACT_APP_HRM + 'leave/leaveapply',
              fullUrl: '',
            },
            {
              name: 'Leave Calendar',
              icon: '',
              url: process.env.REACT_APP_HRM + 'leave/leavecalender',
              fullUrl: '',
            },
            {
              name: 'Holiday',
              icon: '',
              url: process.env.REACT_APP_HRM + 'leave/holiday',
              fullUrl: '',
            },
            {
              name: 'Setup',
              icon: '',
              url: process.env.REACT_APP_HRM + 'leave/setup',
              fullUrl: '',
            },
          ],
        },
        {
          name: 'ID Card',
          icon: 'https://campuslift.blr1.cdn.digitaloceanspaces.com/oogyy/Profile%20Square%202.svg',
          url: '/idcard',
          fullUrl: ''
        },
        {
          name: 'Attendance',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FHRM-Attendance.svg',
          children: [
            {
              name: 'Attendance Muster',
              icon: '',
              url: process.env.REACT_APP_HRM + 'attendance/overview',
              fullUrl: '',
            },
            {
              name: 'Process Attendance',
              icon: '',
              url: process.env.REACT_APP_HRM + 'attendance/process',
              fullUrl: '',
            },
          ],
        },
      ],
    },
    Accounting: {
      name: 'Accounting',
      menu: [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: process.env.REACT_APP_Accounting + 'accounting/dashboard',
          fullUrl: '',
        },
        {
          name: 'Sales & Payments',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FAccounting-Sales-Payment.svg',
          children: [
            {
              name: 'Invoices',
              icon: '',
              route: process.env.REACT_APP_Accounting + 'accounting/invoices',
            },
            {
              name: 'Customers',
              icon: '',
              route: process.env.REACT_APP_Accounting + 'accounting/customers',
            },
            {
              name: 'Products and Services',
              icon: '',
              route: process.env.REACT_APP_Accounting + 'accounting/product-services',
            },
          ],
        },
        {
          name: 'Purchases',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FAccounting-Purchases.svg',
          children: [
            {
              name: 'Bills',
              icon: '',
              route: process.env.REACT_APP_Accounting + 'accounting/bills',
            },
            {
              name: 'Vendors',
              icon: '',
              route: process.env.REACT_APP_Accounting + 'accounting/vendors',
            },
            {
              name: 'Products and Services',
              icon: '',
              route: process.env.REACT_APP_Accounting + 'accounting/productNservices',
            },
          ],
        },
        {
          name: 'Accounting',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FAccounting-Accounting.svg',
          children: [
            {
              name: 'Transactions',
              icon: '',
              route: process.env.REACT_APP_Accounting + 'accounting/transaction',
            },
            {
              name: 'Reconciliation',
              icon: '',
              route: process.env.REACT_APP_Accounting + 'accounting/reconciliation',
            },
            {
              name: 'Chart of accounts',
              icon: '',
              route: process.env.REACT_APP_Accounting + 'accounting/chartOfAccounts',
            },
          ],
        },
      ],
    },
  });
  const [dashboardfiles, setDashboardFiles] = React.useState([]);
  const [undoArray, setundoArray] = React.useState([]);
  const [redoArray, setredoArray] = React.useState([]);
  const [undoPagination, setundoPagination] = useState([]);


  return (

    <GlobalContext.Provider value={{
      search, setSearch,
      exportToPdf, setExportToPdf,
      backDrop, setBackdrop,
      addTextTrigger, setAddTextTrigger,
      selectedFont, setSelectedFont,
      exportModalShow, setExportModalShow,
      currentPage, setCurrentPage,
      fileName, setFileName,
      totalPage, setTotalPage,
      zoom, setZoom,
      canvas, setCanvas,
      canvasArray, setCanvasArray,
      canvasImageArray, setCanvasImageArray,
      toastMessage, setToastMessage,
      showA, setShowA,
      open, setOpen,
      org_id, setOrg_id,
      userInfo, setUserInfo,
      access, setAccess,
      addShapeTrigger, setAddShapeTrigger,
      selectedShape, setSelectedShape,
      selectedFile, setSelectedFile,
      files, setFiles,
      currentAlign, setCurrentAlign,
      CurrentTab, setCurrentTab,
      searchKey, setSearchKey,
      currentMode, setCurrentMode,
      brushWidth, setBrushWidth,
      textColor, settextColor,
      selectedOrgDetail, setSelectedOrgDetail,
      initialOrgFetch, setInitialOrgFetch,
      roles, setRoles,
      innerNavItems, setInnerNavItems,
      role, setRole,
      orgDetail, setOrgDetail,
      selectedFileTrigger, setSelectedFileTrigger,
      appList, setApplist,
      dashboardfiles, setDashboardFiles,
      appMenu, setAppMenu,
      selectedFileType, setSelectedFileType,
      selectedPlanDetail, setSelectedPlanDetail,
      undoArray, setundoArray,
      redoArray, setredoArray,
      undoPagination, setundoPagination,
      appMenuMobile, setAppMenuMobile
    }}>
      {children}
    </GlobalContext.Provider>

  )

}
