import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import GlobalProvider from './context/context';


import './index.scss';

ReactDOM.render(
  <Router>
     <GlobalProvider>
    <App />
     </GlobalProvider>
  </Router>,
  document.getElementById('root'),
);
