import React, { useState } from 'react'
import HomeIcon from '../assets/navigation/Home.svg';
import AppBuilderIcon from '../assets/navigation/app-builder.svg';
import WebBuilderIcon from '../assets/navigation/web-builder.svg';
import AnalyticsIcon from '../assets/navigation/Analytics-side-icon.svg';
import LandingPageBuilderIcon from '../assets/navigation/Landingpage-builder.svg';
import { GlobalContext } from '../../../context/context';
import './GlobalSideBar.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { useNavigate } from 'react-router-dom';
import ExpandMore from '@mui/icons-material/ExpandMore';

function GlobalSideBar() {

  const { CurrentTab, setCurrentTab } = React.useContext(GlobalContext);
  const [toggle, setToggle] = useState(false);
  const { appList } = React.useContext(GlobalContext);
  const { appMenu } = React.useContext(GlobalContext);
  const [activeTab, setActiveTab] = React.useState('Study Materials');
  const [activeTabItems, setActiveTabItems] = React.useState([]);
  const { access } = React.useContext(GlobalContext);
  const { org_id } = React.useContext(GlobalContext);
  const { userInfo } = React.useContext(GlobalContext);
  const { selectedOrgDetail } = React.useContext(GlobalContext);
  const { selectedPlanDetail } = React.useContext(GlobalContext);
  const { roles, setRoles } = React.useContext(GlobalContext);

  const navigate = useNavigate();

  const checkAvailability = () => {
    // console.log(roles);
    // if (roles.includes('SuperAdmin')) {
    if (roles.some(i => i?.roles?.includes('SuperAdmin'))) {
      return true
    } else {
      return false
    }
  }
  // console.log(appList)
  // console.log(appMenu['LMS'])

  function checkAllowedMenus(app, navItem, navList) {
    console.log(navItem, 'role');
    if (navItem.name === 'HRM') {
      if (app.roles === 'SuperAdmin' || app.roles === 'Admin') {
        navList = navList.filter((item) => {
          return item
        })
        navList.forEach(item => {
          if (item.children) {
            item.children = item.children.filter((child) => {
              return child.name !== 'Apply Leave'
            })
          }
        });
        return navList
      } else if (app.roles === 'Senior_Manager' || app.roles === 'Junior_Manager') {
        navList = navList.filter((item) => {
          return item
        })
        navList.forEach(item => {
          if (item.children) {
            item.children = item.children.filter((child) => {
              return child.name !== 'Apply Leave' && child.name !== 'Setup' && child.name !== 'Leave Granter'
            })
          }
        });

        return navList
      } else if (app.roles === 'Senior_Employee' || app.roles === 'Junior_Employee') {
        navList = navList.filter((item) => {
          return item
        })
        navList.forEach(item => {
          if (item.children) {
            item.children = item.children.filter((child) => {
              return child.name !== 'Employee' && child.name !== 'Overview' && child.name !== 'Leave' && child.name !== 'Leave Granter' && child.name !== 'Apply On Behalf' && child.name !== 'Setup' && child.name !== 'Attendance Muster' && child.name !== 'Process Attendance';
            })
          }
        });

        return navList
      }
    }
    if (navItem.name === 'CRM') {
      let permissions
      if (this.userInfo?.extras) {
        permissions = this.setCrmPermissions(JSON.parse(this.userInfo.extras[localStorage.getItem('org_id')][app.app_id]));
      }

      if (app.roles === 'Staff') {
        navList = navList.filter((menuItem) => {
          return menuItem.name !== 'Users';
        });
      }
      return navList.filter((menuItem) => {
        if (permissions.contacts.unselect_contacts)
          return menuItem.name !== 'Contacts';
        if (permissions.sales.unselect_sales)
          return menuItem.name !== 'Sales';
        if (permissions.sales.view_overview)
          return menuItem.name !== 'Overview';
        return true;
      });
    }

    if (navItem.name === 'LMS' || navItem.name === 'Forms' || navItem.name === 'Whiteboard' || navItem.name === 'Accounting' || navItem.name === 'StudyMaterials') {
      return navList
    }
  }
  const MultiLevel = ({ item }) => {
    const [open, setOpen] = React.useState(false);
    const { items: children } = item;
    const handleClick = () => {
      setOpen(!open);
    };

    return (
      <List
        sx={{ width: "100%", maxWidth: 275, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={handleClick}>
          {/* <ListItemIcon>
                        <img src={item?.icon} />
                    </ListItemIcon> */}
          <img src={item?.icon} style={{ marginRight: "20px", marginLeft: '8px' }} />
          <ListItemText primaryTypographyProps={{ fontSize: '14px' }} primary={item.name} />
          {open ? <ExpandLess style={{ color: '#808080' }} /> : <ExpandMore style={{ color: '#808080' }} />}
        </ListItemButton>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item?.children?.map((child, index) => (
              <Selection key={index} item={child} />
            ))}
          </List>
        </Collapse>
      </List>
    );
  };

  const SingleLevel = ({ item }) => {
    return (
      <>
        <ListItem key={item.title} disablePadding>
          <ListItemButton onClick={() => { handleNavigation(item) }}>
            <img src={item?.icon} style={{ marginRight: "20px", marginLeft: '8px' }} />
            <ListItemText primaryTypographyProps={{ fontSize: '14px' }} primary={item.name} />
          </ListItemButton>
        </ListItem>
      </>
    );
  };

  const Selection = ({ item }) => {
    const Component = item.children ? MultiLevel : SingleLevel;

    return <Component item={item} />;
  };

  const DarkTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  const toggleDrawer = (app) => (event) => {
    if ((activeTab == app.name)) {
      setToggle(!toggle);
      setActiveTab(app.name)
      setActiveTabItems(appMenu[(app.name).replace(/\s/g, "")]?.menu)
    } else {
      setActiveTab(app.name)
      setToggle(true);
      setActiveTabItems(appMenu[(app.name).replace(/\s/g, "")]?.menu)
    }
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 300, zIndex: "5000" }}
      role="presentation"
    >
      <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', overflowY: "auto", height: "77vh" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {activeTabItems.map((item, index) => (
          <Selection key={index} item={item} />
        ))}

      </List>
    </Box>
  );
  const handleNavigation = (app) => {
    console.log(app,'apppp')
    if (app === 'Home') {
      window.location.href = process.env.REACT_APP_Home + '?&org_id=' + org_id
    }
    else if (app === 'appBuilder') {
      window.location.href = process.env.REACT_APP_Home + 'home/dashboard/app-builder?access=' + access + '&uid=' + userInfo?.uid + '&org_id=' + org_id;
    } else if (app === 'websiteBuilder') {
      window.location.href = process.env.REACT_APP_WebsiteBuilder + '?access=' + access + '&uid=' + userInfo?.uid + '&org_id=' + org_id;
    }
    else if (activeTab == 'Study Materials') {
      setToggle(false);
      navigate('/', { replace: true });
    }
    else {
      // setToggle(false);

      appList.forEach((apps) => {
        console.log(activeTab.replace(/\s+/g, ''),apps.name)
        if (apps.name == activeTab) {
          if (access && userInfo?.uid && org_id) {
            if (app.url === 'institution-analytics' || app.url === 'lms-analytics' || app.url === 'student-analytics') {
              window.location.href = process.env.REACT_APP_Home + 'home/dashboard/analytics' + '?access=' + access + '&uid=' + userInfo?.uid + '&analyticsUrl=' + app.url;
              console.log(process.env.REACT_APP_Home + 'home/dashboard/analytics' + '?access=' + access + '&uid=' + userInfo?.uid + '&analyticsUrl=' + app.url);
            } else {
              window.location.href = process.env[`REACT_APP_${activeTab.replace(/\s/g, "")}`] + app?.url + '?access=' + access + '&uid=' + userInfo?.uid + '&org_id=' + org_id + '&app_id=' + apps?._id;
              console.log(process.env[`REACT_APP_${activeTab.replace(/\s/g, "")}`] + app?.url + '?access=' + access + '&uid=' + userInfo?.uid + '&org_id=' + org_id + '&app_id=' + apps?._id);
            }

          } else {

            let userInfo = JSON.parse(localStorage.getItem('userInfo'))
            let access = localStorage.getItem('access')
            let org_id = localStorage.getItem('org_id')
            if (app.url === 'institution-analytics' || app.url === 'lms-analytics' || app.url === 'student-analytics') {
              window.location.href = process.env.REACT_APP_Home + 'home/dashboard/analytics'+ '?access=' + access + '&uid=' + userInfo?.uid + '&analyticsUrl=' + app.url;
              console.log(process.env.REACT_APP_Home + 'home/dashboard/analytics' + '?access=' + access + '&uid=' + userInfo?.uid + '&analyticsUrl=' + app.url);
            } else {
              window.location.href = process.env[`REACT_APP_${activeTab.replace(/\s+/g, '')}`] + app?.url + '?access=' + access + '&uid=' + userInfo?.uid + '&org_id=' + org_id + '&app_id=' + apps?._id;
              console.log(process.env[`REACT_APP_${activeTab.replace(/\s/g, "")}`] + app?.url + '?access=' + access + '&uid=' + userInfo?.uid + '&org_id=' + org_id + '&app_id=' + apps?._id);
            }

          }
        }
      })
    }
  }
  const getTitle = (value) => {
    if (!value) {
      return value;
    }
    else {
      if (value === 'LMS') {
        return 'Learning Management System'
      } else if (value === 'CRM') {
        return 'Customer Relationship Management'
      } else if (value === 'HRM') {
        return 'Human Resource Management'
      }
      else if (value === 'Study Materials') {
        if (selectedOrgDetail.org_subtype === 'Schools' || selectedOrgDetail.org_subtype === 'Colleges') {
          return 'Hybrid Classroom'
        } else {
          return 'Content Studio'
        }
      } else {
        return value
      }
    }
  }

  return (
    <>
      <div className="staticSidebar">
        <div style={{ height: '96px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={process.env.REACT_APP_navLogo} style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => {
            window.location.href = process.env.REACT_APP_Home }} />
        </div>
        <div style={{ padding: '3px 0px' , height:'43px' }}>
          <DarkTooltip title="Home" placement="right" style={{ zIndex: '9999 !important' }}>
            <div
              className={'sideBarIcons '}
              onClick={() => {
              }}
            >
              <img onClick={() => { handleNavigation('Home') }} style={{ width: '36px', marginBottom: '2px' }} src={HomeIcon} />
            </div>
          </DarkTooltip>
          {(selectedOrgDetail?.org_subtype === 'Coaching_Center' || selectedOrgDetail?.org_subtype === 'Individual_Tutor') && selectedOrgDetail?.plan_id !== 'default' && checkAvailability() && <DarkTooltip title="App Builder" placement="right" style={{ zIndex: '9999 !important' }}>
            <div
              className={'sideBarIcons '}
              onClick={() => {
              }}
            >
              <img onClick={() => { handleNavigation('appBuilder') }} style={{ width: '36px' }} src={AppBuilderIcon} />
            </div>
          </DarkTooltip>}
          {selectedOrgDetail?.plan_id !== 'default' && checkAvailability() && <DarkTooltip title="Analytics" placement="right" style={{ zIndex: '9999 !important' }}>
            <div style={{ padding: '3px', height:'43px' }}>
              <div
                className={'sideBarIcons ' + ('Analytics' == activeTab ? 'active' : '')}
                onClick={toggleDrawer({ name: 'Analytics' })}
              >
                <img onClick={() => { }} style={{ width: '25px' }} src={AnalyticsIcon} />
              </div>
            </div>
          </DarkTooltip>}
          {/* {selectedOrgDetail?.org_subtype === 'Coaching_Center' &&  selectedOrgDetail?.plan_id !== 'default' && selectedPlanDetail?.name !== 'Free' && checkAvailability() && <DarkTooltip title="Website Builder" placement="right" style={{ zIndex: '9999 !important' }}>
            <div
              className={'sideBarIcons '}
            >
              <img onClick={() => { handleNavigation('websiteBuilder') }} style={{ width: '36px' }} src={WebBuilderIcon} />
            </div>
          </DarkTooltip>}  */}

          {appList.map((app, index) => (
            <div style={{ padding: '3px', height:'43px' }} key={index} onClick={toggleDrawer(app)}>
              {app.name !== 'Analytics' && <DarkTooltip title={getTitle(app.name)} placement="right" style={{ zIndex: '9999 !important' }}>
                <div
                  className={'sideBarIcons ' + (app.name == activeTab ? 'active' : '')}
                  onClick={() => {
                  }}
                >
                  <img style={{ width: '30px' }} src={app.navIcon} />

                </div>
              </DarkTooltip>}
            </div>
          ))}
        </div>
        <div>
          <Drawer

            sx={{
              '& .MuiDrawer-paper': {
                top: '-5px',
                boxShadow: 'none',
                paddingLeft: '60px',
                paddingTop: '20px',
                width: '335px'
              }
            }}

            open={toggle}
            onClose={() => { setToggle(false) }}
          >
            {list('left')}
          </Drawer>

        </div>
      </div>
    </>
  )
}

export default GlobalSideBar