import React from 'react';
import PersistentDrawerLeft from './lib/components/sidebar/sideBarPer';
import Dashboard from './lib/components/dashboard/dashboard';
import ScreenSizeWarning from './lib/components/screenSizeWarning/screenSizeWarning';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { GlobalContext } from './context/context';

const App = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 700;

  const search = useLocation().search;
  const uidParams = new URLSearchParams(search).get('uid');
  const accessParams = new URLSearchParams(search).get('access');
  const org_idPArams = new URLSearchParams(search).get('org_id');
  const { setOrg_id } = React.useContext(GlobalContext);
  const { setUserInfo } = React.useContext(GlobalContext);
  const { setAccess } = React.useContext(GlobalContext);
  const { orgDetail, setOrgDetail } = React.useContext(GlobalContext);
  const { selectedOrgDetail, setSelectedOrgDetail } = React.useContext(GlobalContext);
  const { setSelectedPlanDetail } = React.useContext(GlobalContext);
  const { roles, setRoles } = React.useContext(GlobalContext);
  const { appList, setApplist } = React.useContext(GlobalContext);
  const { appMenu, setAppMenu } = React.useContext(GlobalContext);
  const { setBackdrop } = React.useContext(GlobalContext);
  const { appMenuMobile, setAppMenuMobile } = React.useContext(GlobalContext);

  let rolesTemp = [];


  React.useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = process.env.REACT_APP_favIcon;
  }, []);


  React.useEffect(() => {
    console.log('v3.1')
    getUserInfo();
    getAllOrganizations();
    getOrgDetails();
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener('resize', handleResizeWindow);
    if (search) {
      if (accessParams) {
        localStorage.setItem('access', accessParams);
        setAccess(accessParams);
      }
      if (org_idPArams) {
        localStorage.setItem('org_id', org_idPArams);
        // console.log(org_idPArams)
        setOrg_id(org_idPArams);
      }
    } else {
      if (localStorage.getItem('org_id')) {
        setOrg_id(localStorage.getItem('org_id'));
      }
      if (localStorage.getItem('userInfo')) {
        setUserInfo(JSON.parse(localStorage.getItem('userInfo')));
      }
      if (localStorage.getItem('access')) {
        setAccess(localStorage.getItem('access'));
      }
      getUserInfo();
    }
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);
  React.useEffect(() => {
    // console.log(roles)
    let appMenutemp = appMenu;
    let appMenutempMob = appMenuMobile;
    if (roles.some(i => i?.roles?.includes("Senior_Employee")) || roles.some(i => i?.roles?.includes("Junior_Employee"))) {
      appMenutemp['HRM']['menu'] = [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: 'dashboard',
          fullUrl: '',
        },
        {
          name: 'Employee',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FHRM-Employee.svg',
          children: [
            {
              name: 'Overview',
              icon: '',
              url: 'employee/overview',
              fullUrl: '',
            },
            {
              name: 'Directory',
              icon: '',
              url: 'employee/directory',
              fullUrl: '',
            },
          ],
        },
        {
          name: 'Leave',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FHRM-Leave.svg',
          children: [
            {
              name: 'Leave List',
              icon: '',
              url: 'leavelist',
              fullUrl: '',
            },
            {
              name: 'Leave Granter',
              icon: '',
              url: 'leave/grantleave',
              fullUrl: '',
            },
            {
              name: 'Apply Leave',
              icon: '',
              url: 'leave/leaveapply',
              fullUrl: '',
            },
            {
              name: 'Leave Calendar',
              icon: '',
              url: 'leave/leavecalender',
              fullUrl: '',
            },
            {
              name: 'Holiday',
              icon: '',
              url: 'leave/holiday',
              fullUrl: '',
            }
          ],
        },
        {
          name: 'ID Card',
          icon: 'https://campuslift.blr1.cdn.digitaloceanspaces.com/oogyy/Profile%20Square%202.svg',
          url: '/idcard',
          fullUrl: ''
        },
        {
          name: 'Attendance',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FHRM-Attendance.svg',
          children: [
            {
              name: 'Attendance Muster',
              icon: '',
              url: 'attendance/overview',
              fullUrl: '',
            },
            {
              name: 'Process Attendance',
              icon: '',
              url: 'attendance/process',
              fullUrl: '',
            },
          ],
        },
      ]
      appMenutempMob['HRM']['menu'] = [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: process.env.REACT_APP_HRM + 'dashboard',
          fullUrl: '',
        },
        {
          name: 'Employee',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FHRM-Employee.svg',
          children: [
            {
              name: 'Overview',
              icon: '',
              url: process.env.REACT_APP_HRM + 'employee/overview',
              fullUrl: '',
            },
            {
              name: 'Directory',
              icon: '',
              url: process.env.REACT_APP_HRM + 'employee/directory',
              fullUrl: '',
            },
          ],
        },
        {
          name: 'Leave',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FHRM-Leave.svg',
          children: [
            {
              name: 'Leave List',
              icon: '',
              url: process.env.REACT_APP_HRM + 'leavelist',
              fullUrl: '',
            },
            {
              name: 'Leave Granter',
              icon: '',
              url: process.env.REACT_APP_HRM + 'leave/grantleave',
              fullUrl: '',
            },
            {
              name: 'Apply Leave',
              icon: '',
              url: process.env.REACT_APP_HRM + 'leave/leaveapply',
              fullUrl: '',
            },
            {
              name: 'Leave Calendar',
              icon: '',
              url: process.env.REACT_APP_HRM + 'leave/leavecalender',
              fullUrl: '',
            },
            {
              name: 'Holiday',
              icon: '',
              url: process.env.REACT_APP_HRM + 'leave/holiday',
              fullUrl: '',
            }
          ],
        },
        {
          name: 'ID Card',
          icon: 'https://campuslift.blr1.cdn.digitaloceanspaces.com/oogyy/Profile%20Square%202.svg',
          url: '/idcard',
          fullUrl: ''
        },
        {
          name: 'Attendance',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FHRM-Attendance.svg',
          children: [
            {
              name: 'Attendance Muster',
              icon: '',
              url: process.env.REACT_APP_HRM + 'attendance/overview',
              fullUrl: '',
            },
            {
              name: 'Process Attendance',
              icon: '',
              url: process.env.REACT_APP_HRM + 'attendance/process',
              fullUrl: '',
            },
          ],
        },
      ]
    } else {
      appMenutemp['HRM']['menu'] = [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: 'dashboard',
          fullUrl: '',
        },
        {
          name: 'Employee',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FHRM-Employee.svg',
          children: [
            {
              name: 'Overview',
              icon: '',
              url: 'employee/overview',
              fullUrl: '',
            },
            {
              name: 'Directory',
              icon: '',
              url: 'employee/directory',
              fullUrl: '',
            },
          ],
        },
        {
          name: 'Leave',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FHRM-Leave.svg',
          children: [
            {
              name: 'Overview',
              icon: '',
              url: 'leave/overview',
              fullUrl: '',
            },
            {
              name: 'Leave',
              icon: '',
              url: 'leavelist',
              fullUrl: '',
            },
            {
              name: 'Leave Granter',
              icon: '',
              url: 'leave/grantleave',
              fullUrl: '',
            },
            {
              name: 'Apply on behalf',
              icon: '',
              url: 'leave/leaveapply',
              fullUrl: '',
            },
            {
              name: 'Leave Calendar',
              icon: '',
              url: 'leave/leavecalender',
              fullUrl: '',
            },
            {
              name: 'Holiday',
              icon: '',
              url: 'leave/holiday',
              fullUrl: '',
            },
            {
              name: 'Setup',
              icon: '',
              url: 'leave/setup',
              fullUrl: '',
            },
          ],
        },
        {
          name: 'ID Card',
          icon: 'https://campuslift.blr1.cdn.digitaloceanspaces.com/oogyy/Profile%20Square%202.svg',
          url: '/idcard',
          fullUrl: ''
        },
        {
          name: 'Attendance',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FHRM-Attendance.svg',
          children: [
            {
              name: 'Attendance Muster',
              icon: '',
              url: 'attendance/overview',
              fullUrl: '',
            },
            {
              name: 'Process Attendance',
              icon: '',
              url: 'attendance/process',
              fullUrl: '',
            },
          ],
        },
      ]
      appMenutempMob['HRM']['menu'] = [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: process.env.REACT_APP_HRM + 'dashboard',
          fullUrl: '',
        },
        {
          name: 'Employee',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FHRM-Employee.svg',
          children: [
            {
              name: 'Overview',
              icon: '',
              url: process.env.REACT_APP_HRM + 'employee/overview',
              fullUrl: '',
            },
            {
              name: 'Directory',
              icon: '',
              url: process.env.REACT_APP_HRM + 'employee/directory',
              fullUrl: '',
            },
          ],
        },
        {
          name: 'Leave',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FHRM-Leave.svg',
          children: [
            {
              name: 'Overview',
              icon: '',
              url: process.env.REACT_APP_HRM + 'leave/overview',
              fullUrl: '',
            },
            {
              name: 'Leave',
              icon: '',
              url: process.env.REACT_APP_HRM + 'leavelist',
              fullUrl: '',
            },
            {
              name: 'Leave Granter',
              icon: '',
              url: process.env.REACT_APP_HRM + 'leave/grantleave',
              fullUrl: '',
            },
            {
              name: 'Apply on behalf',
              icon: '',
              url: process.env.REACT_APP_HRM + 'leave/leaveapply',
              fullUrl: '',
            },
            {
              name: 'Leave Calendar',
              icon: '',
              url: process.env.REACT_APP_HRM + 'leave/leavecalender',
              fullUrl: '',
            },
            {
              name: 'Holiday',
              icon: '',
              url: process.env.REACT_APP_HRM + 'leave/holiday',
              fullUrl: '',
            },
            {
              name: 'Setup',
              icon: '',
              url: process.env.REACT_APP_HRM + 'leave/setup',
              fullUrl: '',
            },
          ],
        },
        {
          name: 'ID Card',
          icon: 'https://campuslift.blr1.cdn.digitaloceanspaces.com/oogyy/Profile%20Square%202.svg',
          url: '/idcard',
          fullUrl: ''
        },
        {
          name: 'Attendance',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FHRM-Attendance.svg',
          children: [
            {
              name: 'Attendance Muster',
              icon: '',
              url: process.env.REACT_APP_HRM + 'attendance/overview',
              fullUrl: '',
            },
            {
              name: 'Process Attendance',
              icon: '',
              url: process.env.REACT_APP_HRM + 'attendance/process',
              fullUrl: '',
            },
          ],
        },
      ]
    }


    if (roles.some(i => i?.roles?.includes("Staff"))) {
      appMenutemp['CRM']['menu'] = [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: 'dashboard',
          fullUrl: '',
        },
        {
          name: 'Contacts',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FCRM-Contacts.svg',
          url: 'contacts',
          fullUrl: '',
        },
        {
          name: 'Sales',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FCRM-Sales.svg',
          children: [
            {
              name: 'Overview',
              icon: '',
              url: 'sales/overview',
              fullUrl: '',
            },
            {
              name: 'Deals',
              icon: '',
              url: 'sales/deals',
              fullUrl: '',
            },
            {
              name: 'Tasks',
              icon: '',
              url: 'sales/tasks',
              fullUrl: ''
            },
            {
              name: 'Meetings',
              icon: '',
              url: 'sales/meetings',
              fullUrl: ''
            },
            {
              name: 'Calls',
              icon: '',
              url: 'sales/calls',
              fullUrl: ''
            }
          ],
        },
        {
          name: 'Tickets',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FCRM-tickets.svg',
          url: 'tickets',
          fullUrl: '',
        },

        {
          name: 'Marketing',
          icon: 'https://cdn.oogyy.com/oogyy/banner/2022/12/Marketing.svg',
          url: 'marketing',
          fullUrl: '',
        }
      ]
      appMenutempMob['CRM']['menu'] = [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: process.env.REACT_APP_CRM + 'dashboard',
          fullUrl: '',
        },
        {
          name: 'Contacts',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FCRM-Contacts.svg',
          url: process.env.REACT_APP_CRM + 'contacts',
          fullUrl: '',
        },
        {
          name: 'Sales',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FCRM-Sales.svg',
          children: [
            {
              name: 'Overview',
              icon: '',
              url: process.env.REACT_APP_CRM + 'sales/overview',
              fullUrl: '',
            },
            {
              name: 'Deals',
              icon: '',
              url: process.env.REACT_APP_CRM + 'sales/deals',
              fullUrl: '',
            },
            {
              name: 'Tasks',
              icon: '',
              url: process.env.REACT_APP_CRM + 'sales/tasks',
              fullUrl: ''
            },
            {
              name: 'Meetings',
              icon: '',
              url: process.env.REACT_APP_CRM + 'sales/meetings',
              fullUrl: ''
            },
            {
              name: 'Calls',
              icon: '',
              url: process.env.REACT_APP_CRM + 'sales/calls',
              fullUrl: ''
            }
          ],
        },
        {
          name: 'Tickets',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FCRM-tickets.svg',
          url: process.env.REACT_APP_CRM + 'tickets',
          fullUrl: '',
        },

        {
          name: 'Marketing',
          icon: 'https://cdn.oogyy.com/oogyy/banner/2022/12/Marketing.svg',
          url: process.env.REACT_APP_CRM + 'marketing',
          fullUrl: '',
        }
      ]
    } else {
      appMenutemp['CRM']['menu'] = [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: 'dashboard',
          fullUrl: '',
        },
        {
          name: 'Contacts',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FCRM-Contacts.svg',
          url: 'contacts',
          fullUrl: '',
        },
        {
          name: 'Users',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FCRM-Contacts.svg',
          url: 'users',
          fullUrl: '',
        },
        {
          name: 'Sales',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FCRM-Sales.svg',
          children: [
            {
              name: 'Overview',
              icon: '',
              url: 'sales/overview',
              fullUrl: '',
            },
            {
              name: 'Deals',
              icon: '',
              url: 'sales/deals',
              fullUrl: '',
            },
            {
              name: 'Tasks',
              icon: '',
              url: 'sales/tasks',
              fullUrl: ''
            },
            {
              name: 'Meetings',
              icon: '',
              url: 'sales/meetings',
              fullUrl: ''
            },
            {
              name: 'Calls',
              icon: '',
              url: 'sales/calls',
              fullUrl: ''
            }
          ],
        },
        {
          name: 'Tickets',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FCRM-tickets.svg',
          url: 'tickets',
          fullUrl: '',
        },

        {
          name: 'Marketing',
          icon: 'https://cdn.oogyy.com/oogyy/banner/2022/12/Marketing.svg',
          url: 'marketing',
          fullUrl: '',
        },
        {
          name: 'Settings',
          icon: 'https://cdn.oogyy.com/oogyy/banner/2022/12/CRM-Settings.svg',
          url: 'settings',
          fullUrl: '',
        },
      ]
      appMenutempMob['CRM']['menu'] = [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: process.env.REACT_APP_CRM + 'dashboard',
          fullUrl: '',
        },
        {
          name: 'Contacts',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FCRM-Contacts.svg',
          url: process.env.REACT_APP_CRM + 'contacts',
          fullUrl: '',
        },
        {
          name: 'Users',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FCRM-Contacts.svg',
          url: process.env.REACT_APP_CRM + 'users',
          fullUrl: '',
        },
        {
          name: 'Sales',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FCRM-Sales.svg',
          children: [
            {
              name: 'Overview',
              icon: '',
              url: process.env.REACT_APP_CRM + 'sales/overview',
              fullUrl: '',
            },
            {
              name: 'Deals',
              icon: '',
              url: process.env.REACT_APP_CRM + 'sales/deals',
              fullUrl: '',
            },
            {
              name: 'Tasks',
              icon: '',
              url: process.env.REACT_APP_CRM + 'sales/tasks',
              fullUrl: ''
            },
            {
              name: 'Meetings',
              icon: '',
              url: process.env.REACT_APP_CRM + 'sales/meetings',
              fullUrl: ''
            },
            {
              name: 'Calls',
              icon: '',
              url: process.env.REACT_APP_CRM + 'sales/calls',
              fullUrl: ''
            }
          ],
        },
        {
          name: 'Tickets',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FCRM-tickets.svg',
          url: process.env.REACT_APP_CRM + 'tickets',
          fullUrl: '',
        },

        {
          name: 'Marketing',
          icon: 'https://cdn.oogyy.com/oogyy/banner/2022/12/Marketing.svg',
          url: process.env.REACT_APP_CRM + 'marketing',
          fullUrl: '',
        },
        {
          name: 'Settings',
          icon: 'https://cdn.oogyy.com/oogyy/banner/2022/12/CRM-Settings.svg',
          url: process.env.REACT_APP_CRM + 'settings',
          fullUrl: '',
        },
      ]
    }
    setAppMenu(appMenutemp)
    setAppMenuMobile(appMenutempMob)
  }, [roles]);
  React.useEffect(() => {
    // console.log(appMenu, 'appnemem')
    console.log(',min', roles);
    let appMenutemp = appMenu;
    let appMenutempMob = appMenuMobile;
    if ((selectedOrgDetail.org_subtype == 'Colleges') &&
      roles.some(i => (i?.roles == "SuperAdmin" || i?.roles == "Admin") &&
        (i?.app_id == 'SuperAdmin' || i?.app_id =='OGYAPP1674121998438' || i?.app_id == 'OGYAPP1674587059799' || i?.app_id == 'OGYAPP1680670403200'))) {
      appMenutemp['LMS']['menu'] = [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: 'layout/dashboard/home/prime',
        },
        {
          name: 'Departments',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Department.svg',
          url: 'layout/dashboard/home/department',
        },
        {
          name: 'Classrooms',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Classroom.svg',
          url: 'layout/dashboard/home',
        },
        {
          name: 'Timetable',
          icon: 'https://cdn.oogyy.com/oogyy/app_icons/Timetable.svg',
          url: 'layout/dashboard/timetable',
        },
        {
          name: 'Payments',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Payments.svg',
          url: 'layout/dashboard/payment',
        },
        {
          name: 'Clubs',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Clubs.svg',
          url: 'layout/dashboard/chat/list',
        },
        {
          name: 'Admissions',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Admissions.svg',
          url: 'layout/dashboard/home/admission-decision',
        },
        {
          name: 'Users',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Admissions.svg',
          url: 'layout/dashboard/home/staff',
        },
        {
          name: 'Students',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2Fstudent.svg',
          url: 'layout/dashboard/home/all-students',
        },
        {
          name: 'Approval',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Approval.svg',
          url: 'layout/dashboard/home/approve',
        },
        {
          name: 'Notifications',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
          url: 'layout/dashboard/home/add-notification',
        },
        {
          name: "Faculties",
          icon: "https://cdn.oogyy.com/oogyy/app_icons/2023/faculties%20808080.svg",
          url: "/layout/dashboard/home/faculties",
        },
        {
          name: 'Settings',
          icon: 'https://cdn.oogyy.com/admin-assets/settings1.svg',
          url: 'layout/dashboard/home/settings',
        },
      ]
      appMenutempMob['LMS']['menu'] = [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home/prime',
        },
        {
          name: 'Departments',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Department.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home/department',
        },
        {
          name: 'Classrooms',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Classroom.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home',
        },
        {
          name: 'Timetable',
          icon: 'https://cdn.oogyy.com/oogyy/app_icons/Timetable.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/timetable',
        },
        {
          name: 'Payments',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Payments.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/payment',
        },
        {
          name: 'Clubs',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Clubs.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/chat/list',
        },
        {
          name: 'Admissions',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Admissions.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home/admission-decision',
        },
        {
          name: 'Users',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Admissions.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home/staff',
        },
        {
          name: 'Students',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2Fstudent.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home/all-students',
        },
        {
          name: 'Approval',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Approval.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home/approve',
        },
        {
          name: 'Notifications',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home/add-notification',
        },
        {
          name: "Faculties",
          icon: "https://cdn.oogyy.com/oogyy/app_icons/2023/faculties%20808080.svg",
          url: process.env.REACT_APP_LMS + "/layout/dashboard/classes/my-profile",
        },
        {
          name: 'Settings',
          icon: 'https://cdn.oogyy.com/admin-assets/settings1.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home/settings',
        },
      ]
      // setAppMenu(appMenutemp)
      document.title = process.env.REACT_APP_APP_NAME + ' | Hybrid Classroom';
    } else if ((selectedOrgDetail.org_subtype == 'Schools') &&
      roles.some(i => (i?.roles == "SuperAdmin" || i?.roles == "Admin") &&
        (i?.app_id == 'SuperAdmin' || i?.app_id =='OGYAPP1674121998438' || i?.app_id == 'OGYAPP1674587059799' || i?.app_id == 'OGYAPP1680670403200'))) {
          appMenutemp['LMS']['menu'] = [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: 'layout/dashboard/home/prime',
        },
        {
          name: 'Departments',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Department.svg',
          url: 'layout/dashboard/home/department',
        },
        {
          name: 'Classrooms',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Classroom.svg',
          url: 'layout/dashboard/home',
        },
        {
          name: 'Timetable',
          icon: 'https://cdn.oogyy.com/oogyy/app_icons/Timetable.svg',
          url: 'layout/dashboard/timetable',
        },
        {
          name: 'Payments',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Payments.svg',
          url: 'layout/dashboard/payment',
        },
        {
          name: 'Clubs',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Clubs.svg',
          url: 'layout/dashboard/chat/list',
        },
        {
          name: 'Admissions',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Admissions.svg',
          url: 'layout/dashboard/home/admission-decision',
        },
        {
          name: 'Users',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Admissions.svg',
          url: 'layout/dashboard/home/staff',
        },
        {
          name: 'Students',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2Fstudent.svg',
          url: 'layout/dashboard/home/all-students',
        },
        {
          name: 'Approval',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Approval.svg',
          url: 'layout/dashboard/home/approve',
        },
        {
          name: 'Notifications',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
          url: 'layout/dashboard/home/add-notification',
        },

        // {
        //   name: "Faculties",
        //   icon: "https://cdn.oogyy.com/oogyy/app_icons/2023/faculties%20808080.svg",
        //   url: "/layout/dashboard/home/faculties",
        // },
        {
          name: 'Settings',
          icon: 'https://cdn.oogyy.com/admin-assets/settings1.svg',
          url: 'layout/dashboard/home/settings',
        },
      ]
      appMenutempMob['LMS']['menu'] = [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home/prime',
        },
        {
          name: 'Departments',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Department.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home/department',
        },
        {
          name: 'Classrooms',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Classroom.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home',
        },
        {
          name: 'Timetable',
          icon: 'https://cdn.oogyy.com/oogyy/app_icons/Timetable.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/timetable',
        },
        {
          name: 'Payments',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Payments.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/payment',
        },
        {
          name: 'Clubs',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Clubs.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/chat/list',
        },
        {
          name: 'Admissions',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Admissions.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home/admission-decision',
        },
        {
          name: 'Users',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Admissions.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home/staff',
        },
        {
          name: 'Students',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2Fstudent.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home/all-students',
        },
        {
          name: 'Approval',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Approval.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home/approve',
        },
        {
          name: 'Notifications',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home/add-notification',
        },
        // {
        //   name: "Faculties",
        //   icon: "https://cdn.oogyy.com/oogyy/app_icons/2023/faculties%20808080.svg",
        //   url: "/layout/dashboard/home/faculties",
        // },
        {
          name: 'Settings',
          icon: 'https://cdn.oogyy.com/admin-assets/settings1.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home/settings',
        },
      ]
      // setAppMenu(appMenutemp)
      document.title = process.env.REACT_APP_APP_NAME + ' | Hybrid Classroom';
    }
    else if (selectedOrgDetail.org_subtype == 'Colleges'
      && (roles.some(i => (i?.roles == "Teacher") && (i?.app_id == 'OGYAPP1674121998438' || i?.app_id == 'OGYAPP1674587059799' || i?.app_id == 'OGYAPP1680670403200')))) {
      appMenutemp['LMS']['menu'] = [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: 'layout/dashboard/home/prime',
        },
        {
          name: 'Clubs',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Clubs.svg',
          url: 'layout/dashboard/chat/list',
        },
        {
          name: 'Notifications',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
          url: 'layout/dashboard/home/add-notification',
        },
        {
          name: "Lesson Planner",
          icon: "https://cdn.oogyy.com/oogyy/assets/icon/lessonplan.svg",
          url: "/layout/dashboard/classes/lesson-planner",
        },
        {
          name: "My Profile",
          icon: "https://cdn.oogyy.com/oogyy/app_icons/2023/account-circle-outline%20808080.svg",
          url: "/layout/dashboard/classes/my-profile",
        },
      
      ]
      appMenutempMob['LMS']['menu'] = [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home/prime',
        },
        {
          name: 'Clubs',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Clubs.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/chat/list',
        },
        {
          name: 'Notifications',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home/add-notification',
        },
        {
          name: "Lesson Planner",
          icon: "https://cdn.oogyy.com/oogyy/assets/icon/lessonplan.svg",
          url: "/layout/dashboard/classes/lesson-planner",
        },
        {
          name: "My Profile",
          icon: "https://cdn.oogyy.com/oogyy/app_icons/2023/account-circle-outline%20808080.svg",
          url: process.env.REACT_APP_LMS + "/layout/dashboard/classes/my-profile",
        },
      

      ]
      document.title = process.env.REACT_APP_APP_NAME + ' | Hybrid Classroom';
    }
    else if (selectedOrgDetail.org_subtype == 'Schools'
      && (roles.some(i => (i?.roles == "Teacher") && (i?.app_id == 'OGYAPP1674121998438' || i?.app_id == 'OGYAPP1674587059799' || i?.app_id == 'OGYAPP1680670403200')))) {
      appMenutemp['LMS']['menu'] = [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: 'layout/dashboard/home/prime',
        },
        {
          name: 'Clubs',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Clubs.svg',
          url: 'layout/dashboard/chat/list',
        },
        {
          name: 'Notifications',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
          url: 'layout/dashboard/home/add-notification',
        },
        //  {
        //    name: "My Profile",
        //    icon: "https://cdn.oogyy.com/oogyy/app_icons/2023/account-circle-outline%20808080.svg",
        //    url: "/layout/dashboard/classes/my-profile",
        //  },
       
      ]
      appMenutempMob['LMS']['menu'] = [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home/prime',
        },
        {
          name: 'Clubs',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Clubs.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/chat/list',
        },
        {
          name: 'Notifications',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
          url: process.env.REACT_APP_LMS + 'layout/dashboard/home/add-notification',
        },
        //  {
        //    name: "My Profile",
        //    icon: "https://cdn.oogyy.com/oogyy/app_icons/2023/account-circle-outline%20808080.svg",
        //    url: process.env.REACT_APP_LMS + "/layout/dashboard/classes/my-profile",
        //  },
        

      ]
      document.title = process.env.REACT_APP_APP_NAME + ' | Hybrid Classroom';
    }
    else if (selectedOrgDetail.org_subtype == 'Schools' || selectedOrgDetail.org_subtype == 'Colleges' &&
      (roles.some(i => (i?.roles == "Student") && (i?.app_id == 'OGYAPP1674121998438' || i?.app_id == 'OGYAPP1674587059799' || i?.app_id == 'OGYAPP1680670403200')))) {
      appMenutemp['LMS']['menu'] = [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: 'layout/dashboard/home/prime',
        },
        {
          name: 'Payments',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Payments.svg',
          url: 'layout/dashboard/payment',
        },
        {
          name: 'Clubs',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Clubs.svg',
          url: 'layout/dashboard/chat/list',
        },
        {
          name: 'Notifications',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
          url: 'layout/dashboard/home/add-notification',
        },

      ]
      appMenutempMob['LMS']['menu'] = [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: 'layout/dashboard/home/prime',
        },
        {
          name: 'Payments',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Payments.svg',
          url: 'layout/dashboard/payment',
        },
        {
          name: 'Clubs',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Clubs.svg',
          url: 'layout/dashboard/chat/list',
        },
        {
          name: 'Notifications',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
          url: 'layout/dashboard/home/add-notification',
        },

      ]
      document.title = process.env.REACT_APP_APP_NAME + ' | Hybrid Classroom';
    }
    else if (selectedOrgDetail.org_subtype == 'Coaching_Center' &&
      (roles.some(i => (i?.roles == "SuperAdmin" || i?.roles == "Admin") && (i?.app_id == 'OGYAPP1674121998438' || i?.app_id == 'OGYAPP1674587059799' || i?.app_id == 'OGYAPP1680670403200')))) {
      appMenutemp['LMS']['menu'] = [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: 'layout/dashboard/home/prime',
        },
        {
          name: 'Categories',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Department.svg',
          url: 'layout/dashboard/home/department',
        },
        {
          name: 'Courses',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Classroom.svg',
          url: 'layout/dashboard/home',
        },
        {
          name: 'Payments',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Payments.svg',
          url: 'layout/dashboard/payment',
        },
        {
          name: 'Users',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Admissions.svg',
          url: 'layout/dashboard/home/staff',
        },
        {
          name: 'Students',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2Fstudent.svg',
          url: 'layout/dashboard/home/all-students',
        },
        {
          name: 'Notifications',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
          url: 'layout/dashboard/home/add-notification',
        },
      ]
      document.title = process.env.REACT_APP_APP_NAME + ' | Content Studio';
    } else if (selectedOrgDetail.org_subtype == 'Coaching_Center' &&
      (roles.some(i => (i?.roles == "Teacher") && (i?.app_id == 'OGYAPP1674121998438' || i?.app_id == 'OGYAPP1674587059799' || i?.app_id == 'OGYAPP1680670403200')))) {
      appMenutemp['LMS']['menu'] = [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: 'layout/dashboard/home/prime',
        },
        {
          name: 'Notifications',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
          url: 'layout/dashboard/home/add-notification',
        },
      ]
      document.title = process.env.REACT_APP_APP_NAME + ' | Content Studio';
    } else if (selectedOrgDetail.org_subtype == 'Coaching_Center' &&
      (roles.some(i => (i?.roles == "Student") && (i?.app_id == 'OGYAPP1674121998438' || i?.app_id == 'OGYAPP1674587059799' || i?.app_id == 'OGYAPP1680670403200')))) {
      appMenutemp['LMS']['menu'] = [
        {
          name: 'Dashboard',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
          url: 'layout/dashboard/home/prime',
        },
        {
          name: 'Payments',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Payments.svg',
          url: 'layout/dashboard/payment',
        },
        {
          name: 'My Courses',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Classroom.svg',
          url: 'layout/dashboard/payment',
        },
        {
          name: 'Notifications',
          icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
          url: 'layout/dashboard/home/add-notification',
        },
      ]
      document.title = process.env.REACT_APP_APP_NAME + ' | Content Studio';
    }
    setAppMenu(appMenutemp)
    setAppMenuMobile(appMenutempMob)
    return () => {
      // unsubscribe "onComponentDestroy"
      // window.removeEventListener('resize', handleResizeWindow);
    };
  }, [selectedOrgDetail]);
  const getUserInfo = () => {
    setBackdrop(true)
    if (accessParams && uidParams) {
      axios.defaults.headers.common = { Authorization: `bearer ${accessParams}` };
      axios
        .get(process.env.REACT_APP_AUTH_URL + 'users/' + uidParams)
        .then((res) => {
          setBackdrop(false)
          // console.log(res)
          setUserInfo(res?.data);
          localStorage.setItem('userInfo', JSON.stringify(res?.data));
        })
        .catch((error) => {
          setBackdrop(false)
          logout();
        });
    } else {
      let uid = JSON.parse(localStorage.getItem('userInfo'))?.uid
      let access = localStorage.getItem('access')
      axios.defaults.headers.common = { Authorization: `bearer ${access}` };
      axios
        .get(process.env.REACT_APP_AUTH_URL + 'users/' + uid)
        .then((res) => {
          setBackdrop(false);
          // console.log(res)
          localStorage.setItem('userInfo', JSON.stringify(res?.data));
          setUserInfo(res?.data);
        })
        .catch((error) => {
          setBackdrop(false);
          logout();
        });
    }
  };
  const getOrgDetails = () => {
    // console.log('get org details')
    setBackdrop(true)
    if (accessParams && org_idPArams) {
      axios.defaults.headers.common = { Authorization: `bearer ${accessParams}` };
      axios
        .get(process.env.REACT_APP_DASHBOARD_URL + `users/Organization?id=${org_idPArams}`)
        .then((res) => {
          setBackdrop(false);
          if (res.data) {
            setSelectedOrgDetail(res.data);
            let appMenutemp = appMenu;
            getIndividualPlan(res.data?.plan_id)
            if (res.data.org_subtype == 'Colleges' &&
              (roles.some(i => (i?.roles == "SuperAdmin" || i?.roles == "Admin") && (i?.app_id == 'SuperAdmin' || i?.app_id =='OGYAPP1674121998438' || i?.app_id == 'OGYAPP1674587059799' || i?.app_id == 'OGYAPP1680670403200')))) {
              appMenutemp['LMS']['menu'] = [
                {
                  name: 'Dashboard',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
                  url: 'layout/dashboard/home/prime',
                },
                {
                  name: 'Departments',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Department.svg',
                  url: 'layout/dashboard/home/department',
                },
                {
                  name: 'Classrooms',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Classroom.svg',
                  url: 'layout/dashboard/home',
                },
                {
                  name: 'Timetable',
                  icon: 'https://cdn.oogyy.com/oogyy/app_icons/Timetable.svg',
                  url: 'layout/dashboard/timetable',
                },
                {
                  name: 'Payments',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Payments.svg',
                  url: 'layout/dashboard/payment',
                },
                {
                  name: 'Clubs',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Clubs.svg',
                  url: 'layout/dashboard/chat/list',
                },
                {
                  name: 'Admissions',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Admissions.svg',
                  url: 'layout/dashboard/home/admission-decision',
                },
                {
                  name: 'Users',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Admissions.svg',
                  url: 'layout/dashboard/home/staff',
                },
                {
                  name: 'Students',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2Fstudent.svg',
                  url: 'layout/dashboard/home/all-students',
                },
                {
                  name: 'Approval',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Approval.svg',
                  url: 'layout/dashboard/home/approve',
                },
                {
                  name: 'Notifications',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
                  url: 'layout/dashboard/home/add-notification',
                },
                {
                  name: "Faculties",
                  icon: "https://cdn.oogyy.com/oogyy/app_icons/2023/faculties%20808080.svg",
                  url: "/layout/dashboard/classes/my-profile",
                },
                {
                  name: 'Settings',
                  icon: 'https://cdn.oogyy.com/admin-assets/settings1.svg',
                  url: 'layout/dashboard/home/settings',
                },
              ]
              // setAppMenu(appMenutemp)
              document.title = process.env.REACT_APP_APP_NAME + ' | Hybrid Classroom';
            } else if (res.data.org_subtype == 'Schools' &&
              (roles.some(i => (i?.roles == "SuperAdmin" || i?.roles == "Admin") && (i?.app_id == 'SuperAdmin' || i?.app_id =='OGYAPP1674121998438' || i?.app_id == 'OGYAPP1674587059799' || i?.app_id == 'OGYAPP1680670403200')))) {
              appMenutemp['LMS']['menu'] = [
                {
                  name: 'Dashboard',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
                  url: 'layout/dashboard/home/prime',
                },
                {
                  name: 'Departments',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Department.svg',
                  url: 'layout/dashboard/home/department',
                },
                {
                  name: 'Classrooms',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Classroom.svg',
                  url: 'layout/dashboard/home',
                },
                {
                  name: 'Timetable',
                  icon: 'https://cdn.oogyy.com/oogyy/app_icons/Timetable.svg',
                  url: 'layout/dashboard/timetable',
                },
                {
                  name: 'Payments',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Payments.svg',
                  url: 'layout/dashboard/payment',
                },
                {
                  name: 'Clubs',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Clubs.svg',
                  url: 'layout/dashboard/chat/list',
                },
                {
                  name: 'Admissions',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Admissions.svg',
                  url: 'layout/dashboard/home/admission-decision',
                },
                {
                  name: 'Users',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Admissions.svg',
                  url: 'layout/dashboard/home/staff',
                },
                {
                  name: 'Students',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2Fstudent.svg',
                  url: 'layout/dashboard/home/all-students',
                },
                {
                  name: 'Approval',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Approval.svg',
                  url: 'layout/dashboard/home/approve',
                },
                {
                  name: 'Notifications',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
                  url: 'layout/dashboard/home/add-notification',
                },
                //  {
                //    name: "Faculties",
                //    icon: "https://cdn.oogyy.com/oogyy/app_icons/2023/faculties%20808080.svg",
                //    url: "/layout/dashboard/classes/my-profile",
                //  },
                {
                  name: 'Settings',
                  icon: 'https://cdn.oogyy.com/admin-assets/settings1.svg',
                  url: 'layout/dashboard/home/settings',
                },
              ]
              // setAppMenu(appMenutemp)
              document.title = process.env.REACT_APP_APP_NAME + ' | Hybrid Classroom';
            }
            else if (res.data.org_subtype == 'Colleges' &&
              (roles.some(i => (i?.roles == "Teacher") && (i?.app_id == 'OGYAPP1674121998438' || i?.app_id == 'OGYAPP1674587059799' || i?.app_id == 'OGYAPP1680670403200')))) {
              appMenutemp['LMS']['menu'] = [
                {
                  name: 'Dashboard',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
                  url: 'layout/dashboard/home/prime',
                },
                {
                  name: 'Clubs',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Clubs.svg',
                  url: 'layout/dashboard/chat/list',
                },
                {
                  name: 'Notifications',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
                  url: 'layout/dashboard/home/add-notification',
                },
                {
                  name: "Lesson Planner",
                  icon: "https://cdn.oogyy.com/oogyy/assets/icon/lessonplan.svg",
                  url: "/layout/dashboard/classes/lesson-planner",
                },
                {
                  name: "My Profile",
                  icon: "https://cdn.oogyy.com/oogyy/app_icons/2023/account-circle-outline%20808080.svg",
                  url: "/layout/dashboard/classes/my-profile",
                },
                // {
                //   name: 'Settings',
                //   icon: 'https://cdn.oogyy.com/admin-assets/settings1.svg',
                //   url: 'layout/dashboard/home/settings',
                // },
              ]

              document.title = process.env.REACT_APP_APP_NAME + ' | Hybrid Classroom';
            } else if (res.data.org_subtype == 'Schools' &&
              (roles.some(i => (i?.roles == "Teacher") && (i?.app_id == 'OGYAPP1674121998438' || i?.app_id == 'OGYAPP1674587059799' || i?.app_id == 'OGYAPP1680670403200')))) {
              appMenutemp['LMS']['menu'] = [
                {
                  name: 'Dashboard',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
                  url: 'layout/dashboard/home/prime',
                },
                {
                  name: 'Clubs',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Clubs.svg',
                  url: 'layout/dashboard/chat/list',
                },
                {
                  name: 'Notifications',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
                  url: 'layout/dashboard/home/add-notification',
                },
                // {
                //   name: "My Profile",
                //   icon: "https://cdn.oogyy.com/oogyy/app_icons/2023/account-circle-outline%20808080.svg",
                //   url: "/layout/dashboard/classes/my-profile",
                // },
                // {
                //   name: 'Settings',
                //   icon: 'https://cdn.oogyy.com/admin-assets/settings1.svg',
                //   url: 'layout/dashboard/home/settings',
                // },
              ]

              document.title = process.env.REACT_APP_APP_NAME + ' | Hybrid Classroom';
            }
            else if (res.data.org_subtype == 'Schools' || res.data.org_subtype == 'Colleges' &&
              (roles.some(i => (i?.roles == "Student") && (i?.app_id == 'OGYAPP1674121998438' || i?.app_id == 'OGYAPP1674587059799' || i?.app_id == 'OGYAPP1680670403200')))) {
              appMenutemp['LMS']['menu'] = [
                {
                  name: 'Dashboard',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
                  url: 'layout/dashboard/home/prime',
                },
                {
                  name: 'Payments',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Payments.svg',
                  url: 'layout/dashboard/payment',
                },
                {
                  name: 'Clubs',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Clubs.svg',
                  url: 'layout/dashboard/chat/list',
                },
                {
                  name: 'Notifications',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
                  url: 'layout/dashboard/home/add-notification',
                },

              ]

              document.title = process.env.REACT_APP_APP_NAME + ' | Hybrid Classroom';
            }
            else if (res.data.org_subtype == 'Coaching_Center' &&
              (roles.some(i => (i?.roles == "SuperAdmin" || i?.roles == "Admin") && (i?.app_id == 'SuperAdmin' || i?.app_id =='OGYAPP1674121998438' || i?.app_id == 'OGYAPP1674587059799' || i?.app_id == 'OGYAPP1680670403200')))) {
              appMenutemp['LMS']['menu'] = [
                {
                  name: 'Dashboard',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
                  url: 'layout/dashboard/home/prime',
                },
                {
                  name: 'Categories',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Department.svg',
                  url: 'layout/dashboard/home/department',
                },
                {
                  name: 'Courses',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Classroom.svg',
                  url: 'layout/dashboard/home',
                },
                {
                  name: 'Payments',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Payments.svg',
                  url: 'layout/dashboard/payment',
                },
                {
                  name: 'Users',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Admissions.svg',
                  url: 'layout/dashboard/home/staff',
                },
                {
                  name: 'Students',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2Fstudent.svg',
                  url: 'layout/dashboard/home/all-students',
                },
                {
                  name: 'Notifications',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
                  url: 'layout/dashboard/home/add-notification',
                },
              ]
              document.title = process.env.REACT_APP_APP_NAME + ' | Content Studio';
            } else if (res.data.org_subtype == 'Coaching_Center' &&
              (roles.some(i => (i?.roles == "Teacher") && (i?.app_id == 'OGYAPP1674121998438' || i?.app_id == 'OGYAPP1674587059799' || i?.app_id == 'OGYAPP1680670403200')))) {
              appMenutemp['LMS']['menu'] = [
                {
                  name: 'Dashboard',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
                  url: 'layout/dashboard/home/prime',
                },
                {
                  name: 'Notifications',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
                  url: 'layout/dashboard/home/add-notification',
                },
              ]
              document.title = process.env.REACT_APP_APP_NAME + ' | Content Studio';
            } else if (res.data.org_subtype == 'Coaching_Center' &&
              (roles.some(i => (i?.roles == "Student") && (i?.app_id == 'OGYAPP1674121998438' || i?.app_id == 'OGYAPP1674587059799' || i?.app_id == 'OGYAPP1680670403200')))) {
              appMenutemp['LMS']['menu'] = [
                {
                  name: 'Dashboard',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
                  url: 'layout/dashboard/home/prime',
                },
                {
                  name: 'Payments',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Payments.svg',
                  url: 'layout/dashboard/payment',
                },
                {
                  name: 'My Courses',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Classroom.svg',
                  url: 'layout/dashboard/payment',
                },
                {
                  name: 'Notifications',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
                  url: 'layout/dashboard/home/add-notification',
                },
              ]
              document.title = process.env.REACT_APP_APP_NAME + ' | Content Studio';
            }
            setAppMenu(appMenutemp)

            localStorage.setItem('org', JSON.stringify(res.data));
          } else {
            setSelectedOrgDetail({})
          }
        }).catch(error => {
          setBackdrop(false)
        });
    } else {

      let org_id = localStorage.getItem('org_id')
      let access = localStorage.getItem('access')
      axios.defaults.headers.common = { Authorization: `bearer ${access}` };
      axios
        .get(process.env.REACT_APP_DASHBOARD_URL + `users/Organization?id=${org_id}`)
        .then((res) => {
          setBackdrop(false)
          if (res.data) {
            setSelectedOrgDetail(res.data)
            localStorage.setItem('org', JSON.stringify(res.data));
            getIndividualPlan(res.data?.plan_id)
            if (res.data.org_subtype == 'Colleges' &&
              (roles.some(i => (i?.roles == "SuperAdmin" || i?.roles == 'Admin') && (i?.app_id == 'SuperAdmin' || i?.app_id =='OGYAPP1674121998438' || i?.app_id == 'OGYAPP1674587059799' || i?.app_id == 'OGYAPP1680670403200')))) {
              appMenutemp['LMS']['menu'] = [
                {
                  name: 'Dashboard',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
                  url: 'layout/dashboard/home/prime',
                },
                {
                  name: 'Departments',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Department.svg',
                  url: 'layout/dashboard/home/department',
                },
                {
                  name: 'Classrooms',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Classroom.svg',
                  url: 'layout/dashboard/home',
                },
                {
                  name: 'Timetable',
                  icon: 'https://cdn.oogyy.com/oogyy/app_icons/Timetable.svg',
                  url: 'layout/dashboard/timetable',
                },
                {
                  name: 'Payments',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Payments.svg',
                  url: 'layout/dashboard/payment',
                },
                {
                  name: 'Clubs',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Clubs.svg',
                  url: 'layout/dashboard/chat/list',
                },
                {
                  name: 'Admissions',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Admissions.svg',
                  url: 'layout/dashboard/home/admission-decision',
                },
                {
                  name: 'Users',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Admissions.svg',
                  url: 'layout/dashboard/home/staff',
                },
                {
                  name: 'Students',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2Fstudent.svg',
                  url: 'layout/dashboard/home/all-students',
                },
                {
                  name: 'Approval',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Approval.svg',
                  url: 'layout/dashboard/home/approve',
                },
                {
                  name: 'Notifications',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
                  url: 'layout/dashboard/home/add-notification',
                },
                {
                  name: "Faculties",
                  icon: "https://cdn.oogyy.com/oogyy/app_icons/2023/faculties%20808080.svg",
                  url: "/layout/dashboard/classes/my-profile",
                },
                {
                  name: 'Settings',
                  icon: 'https://cdn.oogyy.com/admin-assets/settings1.svg',
                  url: 'layout/dashboard/home/settings',
                },
              ]
              // setAppMenu(appMenutemp)
              document.title = process.env.REACT_APP_APP_NAME + ' | Hybrid Classroom';
            }
            else if (res.data.org_subtype == 'Schools' &&
              (roles.some(i => (i?.roles == "SuperAdmin" || i?.roles == 'Admin') && (i?.app_id == 'SuperAdmin' || i?.app_id =='OGYAPP1674121998438' || i?.app_id == 'OGYAPP1674587059799' || i?.app_id == 'OGYAPP1680670403200')))) {
              appMenutemp['LMS']['menu'] = [
                {
                  name: 'Dashboard',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
                  url: 'layout/dashboard/home/prime',
                },
                {
                  name: 'Departments',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Department.svg',
                  url: 'layout/dashboard/home/department',
                },
                {
                  name: 'Classrooms',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Classroom.svg',
                  url: 'layout/dashboard/home',
                },
                {
                  name: 'Timetable',
                  icon: 'https://cdn.oogyy.com/oogyy/app_icons/Timetable.svg',
                  url: 'layout/dashboard/timetable',
                },
                {
                  name: 'Payments',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Payments.svg',
                  url: 'layout/dashboard/payment',
                },
                {
                  name: 'Clubs',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Clubs.svg',
                  url: 'layout/dashboard/chat/list',
                },
                {
                  name: 'Admissions',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Admissions.svg',
                  url: 'layout/dashboard/home/admission-decision',
                },
                {
                  name: 'Users',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Admissions.svg',
                  url: 'layout/dashboard/home/staff',
                },
                {
                  name: 'Students',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2Fstudent.svg',
                  url: 'layout/dashboard/home/all-students',
                },
                {
                  name: 'Approval',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Approval.svg',
                  url: 'layout/dashboard/home/approve',
                },
                {
                  name: 'Notifications',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
                  url: 'layout/dashboard/home/add-notification',
                },
                // {
                //   name: "Faculties",
                //   icon: "https://cdn.oogyy.com/oogyy/app_icons/2023/faculties%20808080.svg",
                //   url: "/layout/dashboard/classes/my-profile",
                // },
                {
                  name: 'Settings',
                  icon: 'https://cdn.oogyy.com/admin-assets/settings1.svg',
                  url: 'layout/dashboard/home/settings',
                },
              ]
              // setAppMenu(appMenutemp)
              document.title = process.env.REACT_APP_APP_NAME + ' | Hybrid Classroom';
            }
            else if ( res.data.org_subtype == 'Colleges' &&
              (roles.some(i => (i?.roles == "Teacher") && (i?.app_id == 'OGYAPP1674121998438' || i?.app_id == 'OGYAPP1674587059799' || i?.app_id == 'OGYAPP1680670403200')))) {
              appMenutemp['LMS']['menu'] = [
                {
                  name: 'Dashboard',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
                  url: 'layout/dashboard/home/prime',
                },
                {
                  name: 'Clubs',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Clubs.svg',
                  url: 'layout/dashboard/chat/list',
                },
                {
                  name: 'Notifications',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
                  url: 'layout/dashboard/home/add-notification',
                },
                {
                  name: "Lesson Planner",
                  icon: "https://cdn.oogyy.com/oogyy/assets/icon/lessonplan.svg",
                  url: "/layout/dashboard/classes/lesson-planner",
                },
                {
                  name: "My Profile",
                  icon: "https://cdn.oogyy.com/oogyy/app_icons/2023/account-circle-outline%20808080.svg",
                  url: "/layout/dashboard/classes/my-profile",
                },
                // {
                //   name: 'Settings',
                //   icon: 'https://cdn.oogyy.com/admin-assets/settings1.svg',
                //   url: 'layout/dashboard/home/settings',
                // },
              ]

              document.title = process.env.REACT_APP_APP_NAME + ' | Hybrid Classroom';
            }
            else if (res.data.org_subtype == 'Schools'&&
            (roles.some(i => (i?.roles == "Teacher") && (i?.app_id == 'OGYAPP1674121998438' || i?.app_id == 'OGYAPP1674587059799' || i?.app_id == 'OGYAPP1680670403200')))) {
            appMenutemp['LMS']['menu'] = [
              {
                name: 'Dashboard',
                icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
                url: 'layout/dashboard/home/prime',
              },
              {
                name: 'Clubs',
                icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Clubs.svg',
                url: 'layout/dashboard/chat/list',
              },
              {
                name: 'Notifications',
                icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
                url: 'layout/dashboard/home/add-notification',
              },
              // {
              //   name: "My Profile",
              //   icon: "https://cdn.oogyy.com/oogyy/app_icons/2023/account-circle-outline%20808080.svg",
              //   url: "/layout/dashboard/classes/my-profile",
              // },
              // {
              //   name: 'Settings',
              //   icon: 'https://cdn.oogyy.com/admin-assets/settings1.svg',
              //   url: 'layout/dashboard/home/settings',
              // },
            ]

            document.title = process.env.REACT_APP_APP_NAME + ' | Hybrid Classroom';
          }
            else if (res.data.org_subtype == 'Schools' || res.data.org_subtype == 'Colleges' &&
              (roles.some(i => (i?.roles == "Student") && (i?.app_id == 'OGYAPP1674121998438' || i?.app_id == 'OGYAPP1674587059799' || i?.app_id == 'OGYAPP1680670403200')))) {
              appMenutemp['LMS']['menu'] = [
                {
                  name: 'Dashboard',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
                  url: 'layout/dashboard/home/prime',
                },
                {
                  name: 'Payments',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Payments.svg',
                  url: 'layout/dashboard/payment',
                },
                {
                  name: 'Clubs',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Clubs.svg',
                  url: 'layout/dashboard/chat/list',
                },
                {
                  name: 'Notifications',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
                  url: 'layout/dashboard/home/add-notification',
                },

              ]

              document.title = process.env.REACT_APP_APP_NAME + ' | Hybrid Classroom';
            }
            else if (res.data.org_subtype == 'Coaching_Center' &&
              (roles.some(i => (i?.roles == "SuperAdmin" || i?.roles == 'Admin') && (i?.app_id == 'OGYAPP1674121998438' || i?.app_id == 'OGYAPP1674587059799' || i?.app_id == 'OGYAPP1680670403200')))) {
              appMenutemp['LMS']['menu'] = [
                {
                  name: 'Dashboard',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
                  url: 'layout/dashboard/home/prime',
                },
                {
                  name: 'Categories',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Department.svg',
                  url: 'layout/dashboard/home/department',
                },
                {
                  name: 'Courses',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Classroom.svg',
                  url: 'layout/dashboard/home',
                },
                {
                  name: 'Payments',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Payments.svg',
                  url: 'layout/dashboard/payment',
                },
                {
                  name: 'Users',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Admissions.svg',
                  url: 'layout/dashboard/home/staff',
                },
                {
                  name: 'Students',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2Fstudent.svg',
                  url: 'layout/dashboard/home/all-students',
                },
                {
                  name: 'Notifications',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
                  url: 'layout/dashboard/home/add-notification',
                },
              ]
              document.title = process.env.REACT_APP_APP_NAME + ' | Content Studio';
            } else if (res.data.org_subtype == 'Coaching_Center' &&
              (roles.some(i => (i?.roles == "Teacher") && (i?.app_id == 'OGYAPP1674121998438' || i?.app_id == 'OGYAPP1674587059799' || i?.app_id == 'OGYAPP1680670403200')))) {
              appMenutemp['LMS']['menu'] = [
                {
                  name: 'Dashboard',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
                  url: 'layout/dashboard/home/prime',
                },
                {
                  name: 'Notifications',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
                  url: 'layout/dashboard/home/add-notification',
                },
              ]
              document.title = process.env.REACT_APP_APP_NAME + ' | Content Studio';
            } else if (res.data.org_subtype == 'Coaching_Center' &&
              (roles.some(i => (i?.roles == "Student") && (i?.app_id == 'OGYAPP1674121998438' || i?.app_id == 'OGYAPP1674587059799' || i?.app_id == 'OGYAPP1680670403200')))) {
              appMenutemp['LMS']['menu'] = [
                {
                  name: 'Dashboard',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FDashboard.svg',
                  url: 'layout/dashboard/home/prime',
                },
                {
                  name: 'Payments',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Payments.svg',
                  url: 'layout/dashboard/payment',
                },
                {
                  name: 'My Courses',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Classroom.svg',
                  url: 'layout/dashboard/payment',
                },
                {
                  name: 'Notifications',
                  icon: 'https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2FLMS-Notifications.svg',
                  url: 'layout/dashboard/home/add-notification',
                },
              ]
              document.title = process.env.REACT_APP_APP_NAME + ' | Content Studio';
            }
            setAppMenu(appMenutemp)


          } else {
            setSelectedOrgDetail({})
          }
        }).catch(error => {
          setBackdrop(false)
        });
    }
  }
  const getAllOrganizations = () => {
    setBackdrop(true)

    if (accessParams && uidParams && org_idPArams) {
      axios.defaults.headers.common = { Authorization: `bearer ${accessParams}` };
      axios
        .get(process.env.REACT_APP_DASHBOARD_URL + `users/Organization?user_id=${uidParams}`)
        .then((res) => {
          if (res.data?.length > 0) {
            setOrgDetail(res?.data)
            res?.data.map((org) => {
              if (org.details.org_id == org_idPArams) {
                org.roles.forEach(app => {
                  rolesTemp.push(app)
                });
                setRoles(rolesTemp)
              }
            })
          } else {
            setOrgDetail({})
          }
        });
    } else {

      let uid = JSON.parse(localStorage.getItem('userInfo')).uid
      let access = localStorage.getItem('access')
      let org_id = localStorage.getItem('org_id')
      axios.defaults.headers.common = { Authorization: `bearer ${access}` };
      axios
        .get(process.env.REACT_APP_DASHBOARD_URL + `users/Organization?user_id=${uid}`)
        .then((res) => {
          setBackdrop(false);

          if (res.data?.length > 0) {
            setOrgDetail(res?.data);

            res?.data.map((org) => {
              if (org.details.org_id == org_id) {
                org.roles.forEach((app) => {
                  rolesTemp.push(app);
                });
                setRoles(rolesTemp);
              }
            });
          } else {
            setOrgDetail({});
          }
        }).catch(error => {
          setBackdrop(false)
        });

    }
    // console.log(rolesTemp, 'rolesTemp');
  }
  const getIndividualPlan = (planId) => {
    // console.log('get plan details')
    setBackdrop(true)
    if (accessParams) {
      axios.defaults.headers.common = { Authorization: `bearer ${accessParams}` };
      axios
        .get(process.env.REACT_APP_DASHBOARD_URL + `appmanagement/Plan?id=${planId}`)
        .then((res) => {
          setBackdrop(false);
          if (res.data) {
            let data = JSON.parse(res.data.plan);
            setSelectedPlanDetail(res.data)
            let groups = Object.values(data.groups);
            let appList = [];
            groups.map((group) => {
              group.apps = Object.values(group.apps);
            });
            groups.forEach((group) => {
              if (group.type === 'APP') {
                group.apps.forEach((app) => {
                  if (
                    app.isAvailable &&
                    (rolesTemp.some(i => i.app_id.includes(app._id)) || rolesTemp.some(i => i.roles.includes('SuperAdmin')))
                  ) {
                    app.navIcon = `https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2F${app.name.replaceAll(
                      ' ',
                      '_',
                    )}.svg`;
                    app.Icon = app.name;
                    appList.push(app);
                  }
                });
              }
            });
            appList.push({
              "name": "Analytics",
              "Icon": "Analytics"
            })
            setApplist(appList);
          } else {
            setOrgDetail({});
          }
        }).catch(error => {
          setBackdrop(false)
        });
    } else {
      let access = localStorage.getItem('access')
      axios.defaults.headers.common = { Authorization: `bearer ${access}` };
      axios
        .get(process.env.REACT_APP_DASHBOARD_URL + `appmanagement/Plan?id=${planId}`)
        .then((res) => {
          setBackdrop(false)
          if (res.data) {
            let data = JSON.parse(res.data.plan)
            setSelectedPlanDetail(res.data)
            let groups = Object.values(data.groups)
            let appList = []
            groups.map((group) => {
              group.apps = Object.values(group.apps)
            })
            groups.forEach(group => {
              if (group.type === 'APP') {
                group.apps.forEach((app) => {
                  // console.log(app.isAvailable,rolesTemp.includes(app._id),rolesTemp) 
                  if (app.isAvailable && (rolesTemp.some(i => i.app_id?.includes(app._id)) || rolesTemp.some(i => i.roles.includes('SuperAdmin')))) {
                    app.navIcon = `https://cdn.oogyy.com/oogyy%2Fapp_icons%2F2023%2F${(app.name).replaceAll(' ', '_')}.svg`
                    app.Icon = app.name
                    appList.push(app)
                  }
                });
              }
            });
            appList.push({
              "name": "Analytics",
              "Icon": "Analytics"
            })
            setApplist(appList)
          } else {
            setOrgDetail({})
          }
        }).catch(error => {
          setBackdrop(false)
        });

    }
  };

  const logout = () => {
    localStorage.removeItem('access');
    localStorage.removeItem('uid');
    localStorage.removeItem('org_id');
    window.open(process.env.REACT_APP_Home, '_self');
  };

  if (width > breakpoint) {
    return (
      <>
        <PersistentDrawerLeft />
      </>
    );
  } else {
    return (
      <div>
        <ScreenSizeWarning />
      </div>
    );
  }
};

export default App;
