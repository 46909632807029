import removeCursor from './images/remove.svg';
import pencil from './assets/PenIcon.svg';

const getCursor = ({ type }) => {
  switch (type) {
    case 'eraser': {
      return removeCursor;
    }
    case 'pencil':{
      return pencil
    }
    default: {
      return '';
    }
  }
};

export default getCursor;
