import React from 'react';

function Pencil({fill, stroke}) {
    return (
      <svg width="21" height="20" viewBox="0 0 21 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path d="M19.675 4.49104C19.2973 4.86877 18.9307 5.23538 18.9196 5.602C18.8863 5.95751 19.264 6.32412 19.6195 6.66852C20.1528 7.224 20.6749 7.72393 20.6527 8.2683C20.6305 8.81266 20.0639 9.37925 19.4973 9.93473L14.909 14.5341L13.3315 12.9565L18.053 8.24608L16.9865 7.17956L15.409 8.74601L11.2429 4.57992L15.509 0.324955C15.9422 -0.108318 16.6644 -0.108318 17.0754 0.324955L19.675 2.92459C20.1083 3.33565 20.1083 4.05777 19.675 4.49104ZM0 15.8339L10.6207 5.20206L14.7868 9.36814L4.16609 20H0V15.8339Z" fill={fill}/>
      </svg>
      
    );
}

export default Pencil