import React from 'react';

function Eraser({fill, stroke}) {
    return (
      
      <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.9693 0.624833L20.2563 5.9012C21.0895 6.74499 21.0895 8.09079 20.2563 8.93458L10.4406 18.7503C8.77437 20.4166 6.0721 20.4166 4.39519 18.7503L0.624833 14.98C-0.208278 14.1362 -0.208278 12.7904 0.624833 11.9466L11.9466 0.624833C12.7904 -0.208278 14.1362 -0.208278 14.9693 0.624833ZM2.13084 13.4633L5.91188 17.2336C6.74499 18.0774 8.09079 18.0774 8.93458 17.2336L12.7049 13.4633L7.41789 8.17624L2.13084 13.4633Z" fill={fill}/>
      </svg>
      
      
    );
}

export default Eraser